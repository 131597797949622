var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Cancel from '@mui/icons-material/Cancel';
import Check from '@mui/icons-material/Check';
import Edit from '@mui/icons-material/Edit';
import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import makeComponentTrashable from 'trashable-react';
import { getFirestore } from '../../firebase';
import { useV1Socket } from '../../hooks/useV1Socket';
import { sendUpdateTitleMessage } from '../../utils/ws-v1';
const useStyles = makeStyles(() => ({
    badge: {
        marginLeft: '3px',
        marginRight: '3px',
        paddingLeft: '6px',
        borderRadius: '3px',
        paddingRight: '6px',
        paddingTop: '1px',
        paddingBottom: '2px',
        backgroundColor: 'steelblue',
        display: 'flex',
        flexDirection: 'row',
    },
    editContainer: {
        '& .edit': {
            display: 'none',
        },
        '&:hover .edit': {
            display: 'inherit',
        },
    },
}));
const UpdateConvoMeta = (props) => {
    const { roLink: defaultRoLink, title: defaultTitle } = props;
    const classes = useStyles();
    const [title, setTitle] = useState(undefined);
    const [roLink, setRoLink] = useState(undefined);
    const [edit, setEdit] = useState(false);
    const [links, setLinks] = useState([]);
    const [ws] = useV1Socket();
    useEffect(() => {
        const asyncF = () => __awaiter(void 0, void 0, void 0, function* () {
            (yield getFirestore()).collection('links').onSnapshot((d) => {
                const links = [];
                d.forEach((l) => links.push(l.data()));
                setLinks(links);
            });
        });
        asyncF();
    }, []);
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };
    const handleROLinkChange = (e) => {
        setRoLink(e.target.value);
    };
    const update = () => {
        if (!ws)
            return;
        sendUpdateTitleMessage(ws, {
            title: title === undefined ? defaultTitle : title,
            roLink: roLink === undefined ? defaultRoLink : roLink,
        });
        setTitle(undefined);
        setRoLink(undefined);
        setEdit(false);
    };
    return (React.createElement(React.Fragment, null,
        !edit && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `${classes.editContainer} ${classes.badge}` },
                React.createElement("div", null,
                    React.createElement("span", null, defaultTitle || 'no title'),
                    React.createElement("br", { style: { display: 'block', margin: '-3px 0' } }),
                    React.createElement("span", { style: {
                            fontSize: 12,
                            opacity: 0.8,
                        } }, defaultRoLink ? (React.createElement("a", { style: {
                            textDecoration: 'none',
                            color: 'inherit',
                        }, href: defaultRoLink, target: "_blank", rel: "noopener noreferrer" }, defaultRoLink)) : (React.createElement("div", null, "no ro link")))),
                React.createElement("div", { style: {
                        width: 20,
                        minWidth: 20,
                    } },
                    React.createElement("div", { className: "edit", onClick: () => setEdit(true), style: { cursor: 'pointer' }, role: "heading", "aria-level": 1 },
                        React.createElement(Edit, { style: {
                                width: 15,
                                height: 15,
                                marginTop: 10,
                                marginLeft: 3,
                                color: 'white',
                                opacity: 0.9,
                            } })))))),
        edit && (React.createElement("form", { onSubmit: () => {
                update();
            }, style: {
                background: 'rgba(30,30,30,0.5)',
                borderRadius: 5,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 600,
            } },
            React.createElement(TextField, { variant: "standard", defaultValue: title === undefined ? defaultTitle : title, label: "title", onChange: (e) => handleTitleChange(e), autoFocus: true, id: "title", style: { maxWidth: 300, marginTop: 5 }, margin: "normal", onKeyDown: (ev) => {
                    if (['Enter'].includes(ev.key)) {
                        update();
                    }
                } }),
            React.createElement(FormControl, { variant: "standard", style: {
                    maxWidth: 300,
                    minWidth: 100,
                    marginTop: 5,
                    marginLeft: 10,
                } },
                React.createElement(InputLabel, { shrink: true, htmlFor: "age-label-placeholder" }, "Readonly Link"),
                React.createElement(Select, { variant: "standard", value: roLink === undefined ? defaultRoLink : roLink, onChange: (e) => handleROLinkChange(e), inputProps: {
                        name: 'Readonly link',
                        id: 'ro-link',
                    } },
                    React.createElement(MenuItem, { key: "none", value: "" }, "None"),
                    links.map((l) => (React.createElement(MenuItem, { key: l.url, value: l.url }, l.url))))),
            React.createElement(IconButton, { style: { marginTop: 10 }, type: "submit", size: "large" },
                React.createElement(Check, null)),
            React.createElement(IconButton, { style: { marginTop: 10, color: 'lightgrey' }, onClick: () => {
                    setEdit(false);
                }, size: "large" },
                React.createElement(Cancel, null))))));
};
export default makeComponentTrashable(UpdateConvoMeta);
