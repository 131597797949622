/* eslint-disable react/prop-types */
import { Button, Dialog, DialogContent, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
const MicBlockedDarkEn = '/microphone/mic_block_dark_en.png';
const MicBlockedDarkFr = '/microphone/mic_block_dark_fr.png';
const MicBlockedLightEn = '/microphone/mic_block_light_en.png';
const MicBlockedLightFr = '/microphone/mic_block_light_fr.png';
const useStyles = makeStyles((theme) => ({
    title: {
        margin: 0,
        textAlign: 'center',
        fontWeight: 600,
    },
    subTitle: {
        margin: 0,
        textAlign: 'center',
        fontWeight: 600,
        fontSize: 18,
        marginTop: 30,
        color: theme.palette.ava.blue,
    },
    learnMore: {
        color: theme.palette.ava.blue,
        textAlign: 'center',
        textDecoration: 'underline',
        fontSize: 16,
    },
    ok: {
        width: '60%',
        fontSize: 24,
        marginTop: 30,
        marginBottom: 15,
        borderRadius: 8,
    },
    dialog: {
        borderRadius: 18,
    },
    img: {
        width: '90%',
        boxShadow: theme.shadows[3],
        marginTop: 30,
    },
}));
// eslint-disable-next-line react/prop-types
function MicrophoneDenied({ open }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [dismissed, setDismissed] = useState(false);
    return (React.createElement(Dialog, { onClose: () => setDismissed(true), open: open && !dismissed, maxWidth: "xs", fullWidth: true, className: classes.dialog },
        React.createElement(DialogContent, null,
            React.createElement("div", { style: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: 15,
                } },
                React.createElement("h3", { className: classes.title }, t('modal.request.microphoneAccessDenied')),
                React.createElement("h4", { className: classes.subTitle }, t('modal.request.changeMicrophoneSettings')),
                theme.palette.mode === 'dark' ? (React.createElement("img", { className: classes.img, src: i18n.language === 'fr' ? MicBlockedDarkFr : MicBlockedDarkEn, alt: t('modal.request.microphoneAccessDenied') })) : (React.createElement("img", { className: classes.img, src: i18n.language === 'fr' ? MicBlockedLightFr : MicBlockedLightEn, alt: t('modal.request.microphoneAccessDenied') })),
                React.createElement(Button, { onClick: () => setDismissed(true), disableElevation: true, color: "primary", variant: "contained", className: classes.ok }, t('modal.request.ok')),
                React.createElement(Link, { className: classes.learnMore, target: "_blank", href: i18n.language === 'fr'
                        ? 'https://support.google.com/chrome/answer/2693767?hl=fr'
                        : 'https://support.google.com/chrome/answer/2693767?hl=en', underline: "hover" }, t('accuracy.learnMore'))))));
}
export default MicrophoneDenied;
