var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: 100,
        boxShadow: '0px 4px 4px rgba(112, 144, 176, 0.25)',
        '&:hover': {
            backgroundColor: theme.palette.ava.navy5,
        },
    },
    centered: {
        justifyContent: 'center !important',
    },
    blue: {
        backgroundColor: theme.palette.ava.blue2,
        color: theme.palette.ava.white,
        '&:hover': {
            backgroundColor: theme.palette.ava.blue1,
        },
    },
    white: {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark4,
        color: theme.palette.ava.blue2,
        border: `1px solid ${theme.palette.mode === 'light' ? theme.palette.ava.navy5 : theme.palette.ava.dark5}`,
        boxShadow: theme.palette.mode === 'light'
            ? '0px 4px 4px rgba(112, 144, 176, 0.25)'
            : '-3.65px -3.65px 10.96px rgba(49, 49, 49, 0.5), 3.65px 3.65px 10.96px #000000',
        '&:hover': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.ava.navy5 : theme.palette.ava.dark5,
        },
    },
    flat: {
        boxShadow: 'unset',
    },
}));
const RoundedButton = (_a) => {
    var { children, className, buttonClasses, color, centered, flat, sx } = _a, otherProps = __rest(_a, ["children", "className", "buttonClasses", "color", "centered", "flat", "sx"]);
    const classes = useStyles();
    return (React.createElement(Button, Object.assign({ className: classnames(classes.button, className, { [classes[color]]: color, [classes.flat]: flat, [classes.centered]: centered }, ...(buttonClasses || [])), sx: sx }, otherProps), children));
};
export default RoundedButton;
