/* eslint-disable no-shadow */
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AvaLogoSphere from '../../../assets/images/ava_logo_sphere.png';
import { styles } from '../../modules/scribe/SidebarHelpers';
import { getOS, isMobile } from '../../utils';
const useStyles = makeStyles(styles);
function OpenOrDownloadAva({ setOpenOrDownloadAvaApp }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const handleOpenOrDownloadAvaApp = () => {
        setOpenOrDownloadAvaApp(false);
        setTimeout(() => {
            setOpenOrDownloadAvaApp(true);
        }, 0);
    };
    const OS = getOS();
    if (window.isElectron) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { button: true, key: "app-bridge", classes: {
                focused: classes.root,
            }, onClick: () => handleOpenOrDownloadAvaApp() },
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: AvaLogoSphere, style: { height: 24 }, alt: "Ava logo" })),
            React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: isMobile() ? t('sidebar.openAva') : t('sidebar.openAvaDesktop', { OS }) })),
        React.createElement(Divider, null)));
}
export default OpenOrDownloadAva;
