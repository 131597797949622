import { CircularProgress, Input, Typography } from '@mui/material';
import { Collapse } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../../hooks/useNotifications';
import useRecallAIManager from '../../../hooks/useRecallAIManager';
import { selectIsHost, selectSpeechLang } from '../../../selectors/legacy-conversation';
import { selectRecallAIError, selectRecallAIService, selectRecallAIStatus } from '../../../selectors/recallAI';
import { selectElectronCaptionMode } from '../../../selectors/ui';
import { addNotification } from '../../../store/slices/ccMode';
import { setConnectToMeetingsOpen } from '../../../store/slices/uiState';
import { useAppDispatch } from '../../../store/store';
import { useAppSelector } from '../../../store/store';
import RoundedButton from '../../Buttons/RoundedButton';
import Checkmark from '../../Icons/Checkmark';
import GoogleMeetIcon from '../../Icons/GoogleMeetIcon';
import MicrosoftTeamsIcon from '../../Icons/MicrosoftTeamsIcon';
import ZoomIcon from '../../Icons/ZoomIcon';
import useStyles from './ConnectToMeetingsCard.styles';
const zoomRegex = /(https:\/\/[\w-]*\.?zoom\.us\/(j|my)\/[\d\w?=-]+)/;
const googleMeetRegex = /(?:https?:\/\/)?(?:[\w-]+\.)?meet\.google\.com\/[\w-]+/;
// teams has different urls depending on business or personal accounts
// https://stackoverflow.com/a/72807772
const msTeamsRegex = /(https:\/\/(teams\.live\.com|teams\.microsoft\.com)\/[^\s]+)/;
const meetingLinkRegex = new RegExp(`(?:${zoomRegex.source}|${googleMeetRegex.source}|${msTeamsRegex.source})`);
const ConnectToMeetingsCard = ({ tabletOrMobile }) => {
    const { t } = useTranslation();
    const [meetingLink, setMeetingLink] = useState('');
    const [isLinkVerified, setIsLinkVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [expanded, setExpanded] = useState(true);
    const [buttonState, setButtonState] = useState({
        text: t('connectToOnlineMeetings.connect'),
        disabled: true,
        action: undefined,
        cssClass: 'connect',
    });
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const recallAIErrorMessage = useAppSelector(selectRecallAIError);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const service = useAppSelector(selectRecallAIService);
    const isHost = useAppSelector(selectIsHost);
    const speechLang = useAppSelector(selectSpeechLang);
    const theme = useTheme();
    const verifiedMeetingLinkRef = useRef('');
    const { notify } = useNotifications();
    const loading = recallAIStatus === 'LOADING' || recallAIStatus === 'WAITING_FOR_HOST' || recallAIStatus === 'CONNECTING_TO_AUDIO';
    const dispatch = useAppDispatch();
    const recallAIManager = useRecallAIManager();
    const classes = useStyles({
        electronCaptionMode: !!electronCaptionMode,
        electronWindow: window.isElectron,
        recallAIBotConnected: recallAIStatus === 'CONNECTED' || recallAIStatus === 'CAPTIONING',
        errorMessage,
        loading,
        expanded,
    });
    const connectBot = () => {
        recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.createBot(speechLang, verifiedMeetingLinkRef.current, true);
    };
    const sendNotification = (notification, link) => {
        if (electronCaptionMode) {
            dispatch(addNotification({
                text: t(notification),
                timestamp: Date.now(),
                type: 'default',
                link,
                linkText: link,
            }));
        }
        else {
            notify(t(notification), {
                autoHideDuration: 5000,
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                style: {
                    marginTop: '50px',
                    textAlign: 'center',
                },
                color: theme.palette.mode === 'dark' ? 'black' : 'white',
            }, link ? { url: link, text: link } : undefined);
        }
    };
    const disconnectBot = () => {
        if (recallAIManager) {
            recallAIManager.destroyBot();
            setExpanded(true);
            setMeetingLink('');
            verifiedMeetingLinkRef.current = '';
            sendNotification('connectToOnlineMeetings.disconnected');
            dispatch(setConnectToMeetingsOpen(false));
        }
    };
    const getDefaultButtonState = (disabled) => ({
        text: t('connectToOnlineMeetings.connect'),
        disabled,
        action: connectBot,
        cssClass: 'connect',
    });
    useEffect(() => {
        setButtonState(getDefaultButtonState(true));
    }, []);
    useEffect(() => {
        if (meetingLink.length === 0) {
            setIsLinkVerified(false);
            setErrorMessage('');
            setButtonState(getDefaultButtonState(true));
        }
        else {
            const isVerified = meetingLinkRegex.test(meetingLink);
            setIsLinkVerified(isVerified);
            setErrorMessage(isVerified ? '' : t('connectToOnlineMeetings.invalid'));
            if (isVerified) {
                isVerified && setButtonState(getDefaultButtonState(false));
                // fix long link tricking the backend:
                const match = meetingLink.match(meetingLinkRegex);
                if (match)
                    verifiedMeetingLinkRef.current = match[0];
            }
        }
    }, [meetingLink, recallAIManager]);
    useEffect(() => {
        if (errorMessage.length) {
            setButtonState({
                text: t('connectToOnlineMeetings.invalid'),
                disabled: true,
                cssClass: 'inputErrorMessage',
            });
        }
        else {
            switch (recallAIStatus) {
                case 'NOT_CONNECTED': {
                    setExpanded(true);
                    setButtonState({
                        text: t('connectToOnlineMeetings.connect'),
                        disabled: !verifiedMeetingLinkRef.current.length || !isLinkVerified,
                        action: connectBot,
                        cssClass: 'connect',
                    });
                    break;
                }
                case 'LOADING': {
                    setButtonState({
                        text: t('connectToOnlineMeetings.loading'),
                        disabled: false,
                        cssClass: 'connect',
                    });
                    break;
                }
                case 'WAITING_FOR_HOST': {
                    setButtonState({
                        text: t('connectToOnlineMeetings.waitingForHost'),
                        disabled: false,
                        cssClass: 'connect',
                    });
                    break;
                }
                case 'CONNECTING_TO_AUDIO': {
                    setButtonState({
                        text: t('connectToOnlineMeetings.connectingToAudio'),
                        disabled: false,
                        cssClass: 'connect',
                    });
                    break;
                }
                case 'CONNECTED': {
                    setButtonState({
                        text: t('connectToOnlineMeetings.connectedBot'),
                        disabled: false,
                        cssClass: 'connected',
                    });
                    sendNotification('connectToOnlineMeetings.captioning');
                    break;
                }
                case 'CAPTIONING': {
                    setExpanded(false);
                    setButtonState({
                        text: t('connectToOnlineMeetings.connectedBot'),
                        disabled: !isHost,
                        action: () => recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.prepareDisconnect(),
                        cssClass: 'captioning',
                    });
                    // close modal if on mobile
                    if (tabletOrMobile)
                        dispatch(setConnectToMeetingsOpen(false));
                    break;
                }
                case 'DISCONNECT': {
                    setButtonState({
                        text: t('connectToOnlineMeetings.disconnectBot'),
                        disabled: false,
                        action: disconnectBot,
                        cssClass: 'disconnect',
                    });
                    setExpanded(true);
                    break;
                }
                case 'ERROR':
                    if (recallAIErrorMessage) {
                        if (recallAIErrorMessage !== 'connectToOnlineMeetings.failed') {
                            const link = recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.link;
                            sendNotification(recallAIErrorMessage, link);
                        }
                        else {
                            setButtonState({
                                text: t(recallAIErrorMessage),
                                disabled: false,
                                cssClass: 'recallError',
                            });
                        }
                    }
                    break;
            }
        }
    }, [errorMessage, recallAIStatus]);
    return (React.createElement("section", { className: `${tabletOrMobile ? classes.modal : classes.panel}` },
        tabletOrMobile && (React.createElement(Typography, { variant: "h5", className: classes.text }, t('connectToOnlineMeetings.title'))),
        React.createElement(Collapse, { in: expanded, orientation: "horizontal", collapsedSize: 0 },
            React.createElement(Input, { startAdornment: React.createElement(InputAdornment, { position: "start" },
                    React.createElement(ZoomIcon, null),
                    React.createElement(GoogleMeetIcon, null),
                    React.createElement(MicrosoftTeamsIcon, null)), className: classNames(classes.input, {
                    [classes.inputError]: errorMessage.length > 0,
                }), disabled: !isHost, fullWidth: true, type: "text", placeholder: t('connectToOnlineMeetings.paste'), value: meetingLink, autoFocus: true, disableUnderline: true, onChange: (e) => setMeetingLink(e.currentTarget.value), error: errorMessage.length > 0 || recallAIStatus === 'ERROR' })),
        React.createElement(RoundedButton, { className: classNames(classes.button, classes[`${buttonState.cssClass}`]), color: "blue", onClick: buttonState.action, disabled: buttonState.disabled },
            !expanded && recallAIStatus === 'CAPTIONING' && (React.createElement("span", { className: classes.icon }, service === 'Zoom' ? (React.createElement(ZoomIcon, null)) : service === 'Google Meet' ? (React.createElement(GoogleMeetIcon, null)) : (React.createElement(MicrosoftTeamsIcon, null)))),
            buttonState.text,
            expanded && recallAIStatus === 'CONNECTED' && React.createElement(Checkmark, null),
            loading && (React.createElement("span", { className: classes.spinnerContainer },
                React.createElement(CircularProgress, { className: classes.spinner, size: '1.5rem' }))))));
};
export default ConnectToMeetingsCard;
