import 'audioworklet-polyfill';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useMicPermission from '../../hooks/useMicPermission';
import { useNotifications } from '../../hooks/useNotifications';
import { selectAudioV2Error, selectAudioV2Status, selectVolume } from '../../selectors/audioV2';
import { selectCurrentCaptionsBackgroundColor } from '../../selectors/combined';
import { selectElectronCaptionMode, selectFullScreen } from '../../selectors/ui';
import { RecordingStatus } from '../../store/slices/audioV2';
import { useAppSelector } from '../../store/store';
import { AudioErrorType } from '../AudioV2/errors';
import AudioRecorderUI from './AudioRecorderUI';
const AudioRecorder = (props) => {
    const { hideButton, showExpandedMic } = props;
    const { notify } = useNotifications();
    useMicPermission();
    const theme = useTheme();
    const { t } = useTranslation();
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const recordingStatus = useAppSelector(selectAudioV2Status);
    const fullScreen = useAppSelector(selectFullScreen);
    const volume = useAppSelector(selectVolume);
    const audioV2Error = useAppSelector(selectAudioV2Error);
    const micBackgroundColor = useAppSelector(selectCurrentCaptionsBackgroundColor)(theme);
    useEffect(() => {
        if (!audioV2Error)
            return;
        let errorMessage = '';
        if (audioV2Error.audioErrorType === AudioErrorType.NO_AUDIO_TRACKS) {
            errorMessage = t('snackbar.request.shareAudioNeeded');
        }
        else {
            errorMessage = t('snackbar.request.microphoneMissing');
        }
        notify(errorMessage, {
            variant: 'error',
            preventDuplicate: true,
            autoHideDuration: 10000,
        });
    }, [audioV2Error]);
    const showMinimalMic = electronCaptionMode && !showExpandedMic;
    // Volume is currently between 0 and 128, but this is browser-dependent
    const shadowSpread = Math.min(8, Math.floor(Math.log2(volume) * 3));
    const width = showMinimalMic ? 20 : 56;
    const height = showMinimalMic ? 20 : 56;
    const positionLeft = electronCaptionMode ? `calc(50% - ${width / 2}px)` : 0;
    const positionTop = electronCaptionMode ? `calc(50% - ${height / 2 - 2}px)` : 0;
    return (React.createElement("div", { style: {
            visibility: fullScreen && !electronCaptionMode ? 'hidden' : 'visible',
            display: hideButton ? 'none' : 'inherit',
        } },
        React.createElement("div", { style: {
                position: 'absolute',
                left: positionLeft,
                top: positionTop,
                height,
                width,
                minHeight: height,
                borderRadius: '50%',
                transition: 'all 120ms ease-in 0s, box-shadow 250ms ease-in 0s',
                boxShadow: recordingStatus === RecordingStatus.RECORDING
                    ? `0px 0px 5px ${shadowSpread}px ${micBackgroundColor}90`
                    : 'black 0px 0px 0px 0px',
            } }),
        React.createElement(AudioRecorderUI, Object.assign({}, props, { showMinimalMic: showMinimalMic }))));
};
export default AudioRecorder;
