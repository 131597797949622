import ClickAwayListener from '@mui/material/ClickAwayListener';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AVA_DOMAIN } from '../../../constants';
import { useCopyInviteLink } from '../../../hooks/useCopyInviteLink';
import { selectStatusHostAvaName } from '../../../selectors/legacy-conversation';
import { setIsInviteCardOpen } from '../../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import RoundedButton from '../../Buttons/RoundedButton';
import NewJoinConversation from '../../JoinConversation/NewJoinConversation';
import useStyles from './InviteCard.styles';
const InviteCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { copyInviteLink } = useCopyInviteLink();
    const dispatch = useAppDispatch();
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const statusHostAvaName = useAppSelector(selectStatusHostAvaName);
    return (React.createElement(ClickAwayListener, { onClickAway: () => dispatch(setIsInviteCardOpen(false)) },
        React.createElement("div", { className: classes.root },
            React.createElement("header", { className: classes.header },
                React.createElement("button", { className: classnames(classes.tab, {
                        [classes.activeTab]: currentTabIndex === 0,
                    }), "data-qa": "inviteFriendsButton", onClick: () => setCurrentTabIndex(0), disabled: currentTabIndex === 0 }, t('ccMode.inviteCard.inviteFriends')),
                React.createElement("button", { className: classnames(classes.tab, {
                        [classes.activeTab]: currentTabIndex === 1,
                    }), "data-qa": "joinARoomButton", onClick: () => setCurrentTabIndex(1), disabled: currentTabIndex === 1 }, t('ccMode.inviteCard.joinARoom'))),
            React.createElement("section", { className: classes.panel },
                currentTabIndex === 0 && (React.createElement("div", { className: classes.inviteFriendsPanel },
                    React.createElement("div", { className: classes.linkContainer },
                        React.createElement("span", { className: classes.avaLink },
                            AVA_DOMAIN,
                            "/",
                            React.createElement("span", { className: "{classes.avaName} tauri-no-drag" },
                                "&",
                                statusHostAvaName))),
                    React.createElement(RoundedButton, { className: classes.copyLink, color: "blue", "data-qa": "copyInviteLinkButton", onClick: () => {
                            copyInviteLink();
                            dispatch(setIsInviteCardOpen(false));
                        } }, t('ccMode.inviteCard.copyLink')))),
                currentTabIndex === 1 && (React.createElement("div", { className: classes.joinRoomPanel },
                    React.createElement(NewJoinConversation, { inviteCard: true })))))));
};
export default InviteCard;
