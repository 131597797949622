import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { getColor } from 'random-material-color';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
    },
    avatarContainer: {
        width: 51,
    },
    avatar: {
        height: 36,
        width: 36,
    },
    nameContainer: {
        flexGrow: 1,
    },
    userName: {
        margin: 0,
        padding: 0,
        lineHeight: '16px',
    },
    avaName: {
        margin: 0,
        padding: 0,
        color: (props) => (props.header ? theme.palette.ava.navy : 'grey'),
        fontSize: 14,
        lineHeight: '16px',
    },
}));
const JoinConversationOption = ({ innerRef, data: { user }, isFocused, isSelected, innerProps, selectProps }) => {
    var _a;
    const { header } = selectProps;
    const classes = useStyles({ header });
    return (React.createElement(MenuItem, Object.assign({ ref: innerRef, selected: isFocused, component: "div", style: {
            fontWeight: isSelected ? 500 : 400,
            background: header ? 'white' : 'transparent',
        } }, innerProps),
        React.createElement("div", { className: classes.item },
            React.createElement("div", { className: classes.avatarContainer }, user.userName && (React.createElement(Avatar, { key: "avatar", className: classes.avatar, src: (_a = user.userPhoto) === null || _a === void 0 ? void 0 : _a.url, alt: user.userName, style: { backgroundColor: getColor({ text: user.avaName }) } }, user.userName[0]))),
            React.createElement("div", { className: classes.nameContainer },
                React.createElement(Typography, { className: classes.userName, variant: "subtitle1" }, user.userName),
                React.createElement(Typography, { className: classes.avaName, variant: "body1" }, user.avaName)))));
};
export default JoinConversationOption;
