var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as emailValidator from 'email-validator';
import { auth } from 'firebase';
import { firebaseFunctionsEndpoint } from './http';
export var AuthErrorCategory;
(function (AuthErrorCategory) {
    AuthErrorCategory["Email"] = "email";
    AuthErrorCategory["Password"] = "password";
    AuthErrorCategory["General"] = "general";
})(AuthErrorCategory || (AuthErrorCategory = {}));
export var EmailErrorType;
(function (EmailErrorType) {
    EmailErrorType["InvalidFormat"] = "invalidFormat";
})(EmailErrorType || (EmailErrorType = {}));
export var PasswordErrorType;
(function (PasswordErrorType) {
    PasswordErrorType["PasswordRequirements"] = "passwordRequirements";
    PasswordErrorType["Wrong"] = "wrong";
})(PasswordErrorType || (PasswordErrorType = {}));
export var GeneralAuthErrorType;
(function (GeneralAuthErrorType) {
    GeneralAuthErrorType["unknown"] = "unknown";
})(GeneralAuthErrorType || (GeneralAuthErrorType = {}));
export const evaluatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSymbol = /[!$@*]/.test(password);
    const metCriteria = password.length >= 6 && hasUpperCase && hasLowerCase && hasSymbol;
    const isStrong = hasUpperCase && hasLowerCase && hasSymbol && password.length >= 8;
    if (metCriteria && isStrong)
        return 'strong';
    if (metCriteria)
        return 'good';
    return 'weak';
};
export const validateEmail = (email) => {
    return emailValidator.validate(email);
};
export const validatePassword = (password) => {
    return password && password.length >= 6;
};
export const checkForSSOProvider = (email) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const fetchResponse = yield fetch(`${firebaseFunctionsEndpoint}/widgets/getProviderIdByEmail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });
        const providerId = yield fetchResponse.text();
        return providerId;
    }
    catch (error) {
        if (error.message === 'no device certificate provided') {
            return null;
        }
        console.error('Error checking SSO provider:', error);
        return null;
    }
});
export const initiateSSOLogin = (providerId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const provider = providerId.includes('saml')
            ? new auth.SAMLAuthProvider(providerId)
            : new auth.OAuthProvider(providerId);
        // Safari thinks two await calls aren't related
        // so we're using Promise.all to ensure popups aren't being blocked for our flow
        yield Promise.all([auth().signInWithRedirect(provider)]);
    }
    catch (error) {
        console.error('Error initiating SSO login:', error);
    }
});
