import React from 'react';
import { useTranslation } from 'react-i18next';
import female1 from '../../../assets/icons/female_1.svg';
import female2 from '../../../assets/icons/female_2.svg';
import male1 from '../../../assets/icons/male_1.svg';
import male2 from '../../../assets/icons/male_2.svg';
import neutral1 from '../../../assets/icons/neutral_1.svg';
import neutral2 from '../../../assets/icons/neutral_2.svg';
const images = {
    female1,
    female2,
    male1,
    male2,
    neutral1,
    neutral2,
};
const TtsImage = ({ imageName, imageAltText, className }) => {
    const { t } = useTranslation();
    return React.createElement("img", { className: className, src: images[imageName], alt: t(imageAltText) });
};
export default TtsImage;
