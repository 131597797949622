import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import DesktopExpandedAppBar from '../components/DesktopExpandedAppBar';
import Header from '../components/Header';
import Loading from '../components/Loading';
import Sidebar from '../components/Sidebar';
import { selectFirebaseUser } from '../selectors/auth';
import { selectIsInConversation } from '../selectors/conversation';
import { selectFullScreen, selectIsSideBarOpen } from '../selectors/ui';
import { useAppSelector } from '../store/store';
const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.default,
        display: (props) => (props.fullScreen ? 'inherit' : 'grid'),
        gridTemplateColumns: `
      [sidebar-start] minmax(260px, max-content) [sidebar-end]
      repeat(12, [col-start] 1fr [col-end])
    `,
        gridTemplateRows: `
      [appBar-start] max-content [appBar-end
      header-start] minmax(67px, max-content) [header-end
      main-start] 1fr [main-end]
    `,
        height: 'calc(var(--vh, 1vh) * 100)',
        width: '100vw',
    },
    columnSpacing: {
        columnGap: 10,
    },
    electronRoot: {
        gridTemplateRows: `
      [appBar-start] max-content [appBar-end
      header-start] minmax(99px, max-content) [header-end
      main-start] 1fr [main-end]
    `,
    },
    rootSidebarClosed: {
        gridTemplateColumns: `
      [sidebar-start] 0 [sidebar-end]
      repeat(12, [col-start] 1fr [col-end])
    `,
    },
    rootSidebarClosedAuthenticated: {
        gridTemplateColumns: `
      [sidebar-start] max-content [sidebar-end]
      repeat(12, [col-start] 1fr [col-end])
    `,
    },
    appBar: {
        gridColumn: 'sidebar-start / col-end 12',
        gridRow: 'appBar-start / appBar-end',
    },
    header: {
        gridColumn: 'col-start / col-end 12',
        gridRow: 'header-start / header-end',
    },
    sidebar: {
        gridColumn: 'sidebar-start / sidebar-end',
        gridRow: 'header-start / main-end',
    },
    main: {
        gridColumn: 'col-start / col-end 12',
        gridRow: 'main-start / main-end',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: 'auto',
    },
}));
const Default = ({ children }) => {
    const theme = useTheme();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const sidebar = useAppSelector(selectIsSideBarOpen);
    const isInConversation = useAppSelector(selectIsInConversation);
    const fullScreen = useAppSelector(selectFullScreen);
    const classes = useStyles({ fullScreen });
    const { search } = useLocation();
    // this is to prevent the layout glitching when user is being redirected
    // in desktop login for a second or so
    if (search.includes('auth') && firebaseUser) {
        return React.createElement(Loading, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classnames(classes.root, { [classes.columnSpacing]: (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) || isInConversation }, {
                [!(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) ? classes.rootSidebarClosedAuthenticated : classes.rootSidebarClosed]: !sidebar,
            }, { [classes.electronRoot]: (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) && window.isElectron }) },
            window.isElectron && React.createElement(DesktopExpandedAppBar, { className: classes.appBar }),
            React.createElement(Header, { className: classnames(classes.header) }),
            React.createElement(Sidebar, { className: classnames(classes.sidebar), sidebarShown: sidebar }),
            React.createElement("main", { id: "main-content" // Needed for lazy load transcripts
                , className: classnames(classes.main), style: {
                    backgroundColor: theme.palette.background.default,
                } }, children))));
};
export default Default;
