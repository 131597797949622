import { Fab, Hidden, Popover, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '../../../assets/icons/add.svg';
import InviteCard from '../../components/Cards/InviteCard/InviteCard';
import { selectElectronCaptionMode, selectIsInviteCardOpen } from '../../selectors/ui';
import { setIsInviteCardOpen } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { SNCF } from '../../utils';
const useStyles = makeStyles((theme) => ({
    fab: {
        backgroundColor: theme.palette.ava.navy,
        borderRadius: 22,
        boxShadow: 'none',
        color: 'white',
        fontSize: 16,
        fontWeight: 600,
        height: 44,
        marginLeft: 4.5,
        marginRight: 5,
        marginTop: 0,
        padding: '8px 15px',
        textTransform: 'none',
        width: 'auto',
        flexShrink: 0,
        '&:hover': {
            backgroundColor: theme.palette.ava.navy,
            opacity: 0.9,
        },
    },
    icon: {},
    text: { marginLeft: 10 },
    inviteParticipantCard: {
        background: theme.palette.ccMode.backgroundGradient,
        color: 'white',
        height: '100%',
    },
}));
const AddParticipant = React.memo((props) => {
    const { scribe, hideText } = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();
    const buttonRef = useRef(null);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const isInviteCardOpen = useAppSelector(selectIsInviteCardOpen);
    const dispatch = useAppDispatch();
    if (electronCaptionMode || SNCF) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { disableInteractive: true, title: t('addParticipant.invite'), "aria-label": "share-link", enterDelay: 300, leaveDelay: 200 },
            React.createElement(Fab, { ref: buttonRef, variant: "extended", className: classes.fab, "data-qa": "inviteParticipantButton", onClick: () => {
                    dispatch(setIsInviteCardOpen(!isInviteCardOpen));
                } },
                React.createElement("img", { height: "22", width: "22", alt: "add", className: classes.icon, src: AddIcon }),
                !scribe && !hideText && (React.createElement(Hidden, { only: ['xs', 'sm'] },
                    React.createElement("span", { className: classes.text }, t('addParticipant.add')))))),
        buttonRef.current && (React.createElement(Popover, { open: isInviteCardOpen, anchorEl: buttonRef.current, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, transformOrigin: { vertical: 'top', horizontal: 'right' }, PaperProps: {
                style: {
                    marginTop: 8,
                    minWidth: 385,
                    height: 150,
                    background: theme.palette.ccMode.backgroundGradient,
                    borderRadius: 8,
                },
            } },
            React.createElement("div", { className: classes.inviteParticipantCard },
                React.createElement(InviteCard, null))))));
});
export default AddParticipant;
