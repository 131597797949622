/* eslint-disable no-shadow */
import Language from '@mui/icons-material/Language';
import { Menu, MenuItem } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectDisplayDev } from '../../selectors/v1Session';
import { enableDevMode } from '../../store/slices/v1Session';
import { useAppDispatch } from '../../store/store';
import { changeLanguage } from '../../utils/i18n';
import * as i18nUtils from '../../utils/i18n';
function LanguageSwitcher() {
    const [devModeComptor, setDevModeComptor] = useState(0);
    const [langAnchorEl, setLangAnchorEl] = useState(null);
    const displayDev = useSelector(selectDisplayDev);
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const incrementDevModeComptor = () => {
        if (devModeComptor === 5) {
            dispatch(enableDevMode());
        }
        setDevModeComptor(devModeComptor + 1);
    };
    const updateLanguage = (lang) => {
        changeLanguage(lang, i18n, t, dispatch);
        setLangAnchorEl(null);
    };
    const handleLangClick = (event) => {
        setLangAnchorEl(event.currentTarget);
        incrementDevModeComptor();
    };
    return (React.createElement(ListItem, null,
        displayDev && (React.createElement(ListItemText, null,
            React.createElement(Typography, { variant: "subtitle1", color: "primary", onClick: () => incrementDevModeComptor() },
                React.createElement("small", null, String(process.env.TAG_COMMIT))))),
        React.createElement("button", { style: {
                display: 'flex',
                flexDirection: 'row',
                position: 'absolute',
                right: 10,
            }, onClick: handleLangClick, className: "a-button", type: "submit" },
            React.createElement(Language, { style: {
                    width: 16,
                    height: 16,
                    marginTop: 8,
                    marginRight: 5,
                    color: '#777',
                } }),
            React.createElement(Typography, { "aria-owns": langAnchorEl ? 'simple-menu' : undefined, color: "primary", "aria-haspopup": "true", variant: "subtitle1" },
                React.createElement("small", null, t('sidebar.language')))),
        React.createElement(Menu, { id: "simple-menu", anchorEl: langAnchorEl, open: Boolean(langAnchorEl) }, i18nUtils.langs.map((lg) => (React.createElement(MenuItem, { key: lg, onClick: () => updateLanguage(lg), selected: lg === i18n.language }, i18nUtils.langsDisplay[lg]))))));
}
export default LanguageSwitcher;
