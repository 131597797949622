// For more info see https://webrtc.org/
// This file is supposed to track 1-1 backend/node/captioner/api/helpers/webrtc.ts
// In ideal scenario the WebRTCManager on all the sides of the WebRTC communication
// has the same exact code, and the only asymmetry should be 'politeness'.
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { setWebRTCConnectionStatus, stopRecording } from '../store/slices/audioV2';
import { singletonTrackPlayer } from './audio';
import * as segment from './segment';
const WEBRTC_CONFIG = {
// Defaults to 0, but this might make establishing a connection faster
// iceCandidatePoolSize: 10,
};
export const GOOGLE_STUNS_SERVER = 'stun:stun.l.google.com:19302';
export class WebRTCManager {
    constructor(polite, turnCredentials, dispatch) {
        this.turnCredentials = [];
        this.messageBuffer = [];
        this.trackListeners = [];
        // https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Perfect_negotiation
        // Perfect negotiation involves a 'polite' and 'impolite' peers. That way
        // it's easy to handle offer collisions - polite peers always drop their own
        // offers, while impolite always force their own offers. Otherwise figuring
        // out which offer to pursue while both are in transit would be difficult.
        this.polite = false;
        this.makingOffer = false;
        this.dispatch = dispatch;
        // The object can be constructed early, and it will be able to handle
        // messages early. However, it will buffer all the signaling messages
        // it wants to send, and only send them when initialize is called.
        this.polite = polite;
        this.turnCredentials = turnCredentials;
        if (!window.RTCPeerConnection) {
            trackFailedToCreatePeerConnection();
            return;
        }
        this.resetPeerConnection();
    }
    maybeResetPeerConnection() {
        if (this.peerConnection.connectionState === 'failed') {
            this.peerConnection.close();
        }
        if (this.peerConnection.connectionState === 'closed') {
            this.resetPeerConnection();
        }
    }
    resetPeerConnection() {
        if (this.peerConnection && this.peerConnection.connectionState !== 'closed') {
            this.peerConnection.close();
        }
        this.peerConnection = new RTCPeerConnection(Object.assign(Object.assign({}, WEBRTC_CONFIG), { iceServers: [...this.turnCredentials, { urls: GOOGLE_STUNS_SERVER }] }));
        trackPeerConnectionCreated();
        this.peerConnection.addEventListener('icecandidate', (event) => {
            if (event.candidate) {
                this.handleLocalIceCandidate(event.candidate);
            }
        });
        this.peerConnection.addEventListener('negotiationneeded', () => __awaiter(this, void 0, void 0, function* () {
            this.initiateNegotiation();
        }));
        this.peerConnection.addEventListener('track', (event) => {
            this.trackListeners.forEach((f) => f(event.track));
        });
        this.peerConnection.addEventListener('connectionstatechange', () => {
            console.log('connection state change', this.peerConnection.connectionState);
            this.dispatch(setWebRTCConnectionStatus(this.peerConnection.connectionState));
            trackWebRTCConnectionStateChange(this.peerConnection.connectionState);
            if (this.peerConnection.connectionState === 'failed' || this.peerConnection.connectionState === 'closed') {
                Sentry.captureMessage(`RTCPeerConnection in state ${this.peerConnection.connectionState}`, {
                    level: Severity.Warning,
                    tags: {
                        category: 'webrtc',
                    },
                });
                console.log('stopping audio due to peer connection failure or closure');
                this.dispatch(stopRecording());
            }
        });
        this.peerConnection.addEventListener('iceconnectionstatechange', () => {
            console.log('ICE connection state change', this.peerConnection.iceConnectionState);
            trackWebRTCIceConnectionStateChange(this.peerConnection.iceConnectionState);
            if (this.peerConnection.iceConnectionState === 'failed' || this.peerConnection.iceConnectionState === 'closed') {
                Sentry.captureMessage(`RTCPeerConnection in ICE state ${this.peerConnection.iceConnectionState}`, {
                    level: Severity.Warning,
                    tags: {
                        category: 'webrtc',
                    },
                });
                console.log('stopping audio due to peer connection failure or closure');
                this.dispatch(stopRecording());
            }
        });
    }
    updateIceServers(turnCredentials) {
        if (!this.peerConnection)
            return;
        this.turnCredentials = turnCredentials;
        try {
            this.peerConnection.setConfiguration(Object.assign(Object.assign({}, WEBRTC_CONFIG), { iceServers: [...turnCredentials, { urls: GOOGLE_STUNS_SERVER }] }));
        }
        catch (e) {
            Sentry.captureException(e, {
                tags: {
                    category: 'webrtc',
                },
            });
        }
    }
    destructor() {
        var _a;
        (_a = this.peerConnection) === null || _a === void 0 ? void 0 : _a.close();
    }
    initialize(onMessage) {
        // This can only be called once the signaling channel (onMessage function) is ready
        // to accept messages. It will flush all the messages accumulated in the buffer
        // before the signaling channel was ready.
        this.onMessage = onMessage;
        this.maybeFlushMessageBuffer();
    }
    addTrackListener(f) {
        // This will get triggered any time the server publishes a new track.
        this.trackListeners.push(f);
    }
    initiateNegotiation() {
        return __awaiter(this, void 0, void 0, function* () {
            this.maybeResetPeerConnection();
            try {
                this.makingOffer = true;
                // Calling it with no arguments 'does the right thing'
                // (check the 'perfect negotiation' link above).
                yield this.peerConnection.setLocalDescription();
                yield this.sendMessage({
                    offer: this.peerConnection.localDescription,
                });
            }
            finally {
                this.makingOffer = false;
            }
        });
    }
    handleV1Message(message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!message)
                return false;
            if (message.offer && message.answer) {
                return false;
            }
            if (message.offer) {
                yield this.handleOffer(message.offer);
                return true;
            }
            if (message.answer) {
                yield this.acceptAnswer(message.answer);
                return true;
            }
            if (message.iceCandidate) {
                yield this.addRemoteIceCandidate(message.iceCandidate);
                return true;
            }
            return false;
        });
    }
    handleOffer(offer) {
        return __awaiter(this, void 0, void 0, function* () {
            this.maybeResetPeerConnection();
            const offerCollision = this.makingOffer || this.peerConnection.signalingState !== 'stable';
            if (offerCollision) {
                if (this.polite) {
                    yield this.peerConnection.setLocalDescription({ type: 'rollback' });
                }
            }
            yield this.peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
            const answer = yield this.peerConnection.createAnswer();
            yield this.peerConnection.setLocalDescription(answer);
            this.sendMessage({
                answer,
            });
        });
    }
    acceptAnswer(answer) {
        return __awaiter(this, void 0, void 0, function* () {
            const remoteDesc = new RTCSessionDescription(answer);
            try {
                yield this.peerConnection.setRemoteDescription(remoteDesc);
            }
            catch (e) {
                // If setting the remote description failed for whatever reason, then the
                // whole connection needs to be killed.
                Sentry.captureException(e, {
                    tags: {
                        category: 'webrtc',
                    },
                });
                this.peerConnection.close();
            }
        });
    }
    addRemoteIceCandidate(iceCandidate) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.peerConnection.addIceCandidate(iceCandidate);
            }
            catch (e) { }
        });
    }
    handleLocalIceCandidate(candidate) {
        this.sendMessage({
            iceCandidate: candidate,
        });
    }
    sendMessage(message) {
        this.messageBuffer.push(message);
        this.maybeFlushMessageBuffer();
    }
    maybeFlushMessageBuffer() {
        if (this.onMessage) {
            for (const message of this.messageBuffer) {
                this.onMessage(message);
            }
            this.messageBuffer = [];
        }
    }
    addTrack(track, stream) {
        this.maybeResetPeerConnection();
        trackWebRTCTrackAdded();
        return this.peerConnection.addTrack(track, stream);
    }
    removeSender(sender) {
        try {
            // This might fail if the peer connection is closed or failed, but
            // in such cases, we don't care about removing the track.
            this.peerConnection.removeTrack(sender);
        }
        catch (e) { }
    }
}
let currentTurnCredentials = [];
let singletonWebRTCManager;
export const setTurnCredentials = (turnCredentials) => {
    var _a;
    currentTurnCredentials = turnCredentials;
    (_a = getSingletonWebRTCManager()) === null || _a === void 0 ? void 0 : _a.updateIceServers(turnCredentials);
};
export const recreateSingletonWebRTCManager = (dispatch) => {
    if (!window.RTCPeerConnection)
        return;
    if (singletonWebRTCManager)
        singletonWebRTCManager.destructor();
    singletonWebRTCManager = new WebRTCManager(true, currentTurnCredentials, dispatch);
    // Currently we play the most recent track added by the server. This can
    // ultimately be smarter, and we can potentially hold a few tracks and choose
    // them on the client rather than by sending a new downAudio request.
    singletonWebRTCManager.addTrackListener((track) => {
        singletonTrackPlayer.setTrack(track);
    });
};
export const getSingletonWebRTCManager = () => {
    return singletonWebRTCManager;
};
const trackFailedToCreatePeerConnection = () => {
    segment.track('Web - WebRTC - Failed to create peer connection');
};
const trackPeerConnectionCreated = () => {
    segment.track('Web - WebRTC - Peer connection created');
};
const trackWebRTCTrackAdded = () => {
    segment.track('Web - WebRTC - Track added');
};
const trackWebRTCConnectionStateChange = (connectionState) => {
    segment.track('Web - WebRTC - Connection State Change', {
        connectionState,
    });
};
const trackWebRTCIceConnectionStateChange = (iceConnectionState) => {
    segment.track('Web - WebRTC - ICEConnection State Change', {
        iceConnectionState,
    });
};
