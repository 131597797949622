import { CircularProgress, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmailVerificationDarkImg from '../../../assets/images/email-verification/email-verification-dark.png';
import EmailVerificationLightImg from '../../../assets/images/email-verification/email-verification-light.png';
import { FloatingCard } from '../../components/Cards';
const useStyles = makeStyles((theme) => ({
    loading: {
        marginTop: 32,
        ['@media (max-height: 600px)']: {
            marginTop: 'unset',
        },
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: '-10vh',
        ['@media (max-height: 600px)']: {
            justifyContent: 'center',
            marginTop: 24,
        },
    },
    icon: {
        color: theme.palette.ava.blue,
        fontSize: 100,
    },
    card: {
        paddingTop: 32,
        paddingBottom: 32,
        width: 536,
        minHeight: 348,
        // TODO: Revise the design for middleContainer
        // This should be 200px, but because of padding done in middleContainer, this needed to be reduced.
        marginTop: 200 - 96,
        ['@media (max-height: 600px)']: {
            marginTop: -30,
        },
        ['@media (max-width: 960px)']: {
            paddingTop: 24,
            paddingBottom: 24,
            width: 480,
            minHeight: 320,
        },
        ['@media (max-width: 520px)']: {
            paddingTop: 16,
            paddingBottom: 16,
            width: 336,
            minHeight: 282,
        },
    },
    topContainer: {
        marginBottom: 24,
        '& > :not(:last-child)': {
            marginBottom: 16,
        },
        ['@media (max-width: 520px)']: {
            marginBottom: 12,
        },
    },
    imageContainer: {
        height: 100,
        width: 108,
        margin: '0 auto',
        ['@media (max-width: 520px)']: {
            height: 76,
            width: 82,
        },
    },
    image: {
        height: '100%',
        width: '100%',
    },
    heading: {
        marginTop: 0,
        color: theme.palette.ava.blue2,
        fontSize: 24,
        lineHeight: '30px',
        ['@media (max-width: 520px)']: {
            fontSize: 18,
            lineHeight: '24px',
        },
    },
    action: {
        margin: 0,
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '22px',
        '& > *': {
            display: 'inline-block',
        },
        ['@media (max-width: 960px)']: {
            marginLeft: 18,
            marginRight: 18,
            fontSize: 16,
        },
        ['@media (max-width: 520px)']: {
            margin: 0,
            fontSize: 14,
            lineHeight: '18px',
        },
    },
    email: {
        color: theme.palette.ava.blue1,
    },
    bottomContainer: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '22px',
        '& > *': {
            margin: 0,
        },
        ['@media (max-width: 960px)']: {
            fontSize: 14,
            lineHeight: '18px',
        },
    },
    options: {
        margin: 0,
    },
    recommendation: {
        color: theme.palette.ava.blue2,
    },
    fakeLink: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.ava.blue1,
        },
    },
}));
const ScribeLoginEmailVerification = (props) => {
    const { email, loading, reset } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const onKeyDown = (e) => {
        if (e.keyCode === 27) {
            reset();
        }
    };
    if (loading) {
        return (React.createElement("div", { className: classnames(classes.container, classes.loading) },
            React.createElement(CircularProgress, { color: "secondary", style: { margin: '0 auto' } })));
    }
    return (React.createElement("div", { className: classes.container },
        React.createElement(FloatingCard, { className: classes.card },
            React.createElement("div", { className: classes.topContainer },
                React.createElement("div", { className: classes.imageContainer },
                    React.createElement("img", { className: classes.image, src: theme.palette.mode === 'light' ? EmailVerificationLightImg : EmailVerificationDarkImg })),
                React.createElement("h3", { className: classes.heading, "data-qa": "text_check_your_mailbox" }, t('signin-messages.verifyEmailLoading.checkEmail')),
                React.createElement("p", { className: classes.action },
                    React.createElement("span", null, t('signin-messages.verifyEmailLoading.clickOnValidationLinkSentTo')),
                    "\u00A0",
                    React.createElement("span", { className: classes.email }, email),
                    "\u00A0",
                    React.createElement("span", null, t('signin-messages.verifyEmailLoading.toVerifyEmail')))),
            React.createElement("div", { className: classes.bottomContainer },
                React.createElement("p", null, t('signin-messages.verifyEmailLoading.youDontGetOurEmail')),
                React.createElement("p", { className: classes.options },
                    React.createElement("span", { className: classes.recommendation }, t('signin-messages.verifyEmailLoading.tryInSpam')),
                    "\u00A0",
                    React.createElement("span", null, t('signin-messages.verifyEmailLoading.or')),
                    "\u00A0",
                    React.createElement("span", { className: classnames(classes.recommendation, classes.fakeLink), role: "link", tabIndex: 0, onClick: reset, onKeyDown: (e) => onKeyDown(e), "data-qa": "tryDifferentEmail" }, t('signin-messages.verifyEmailLoading.tryAnotherAdress')))))));
};
export default ScribeLoginEmailVerification;
