import TextField from '@mui/material/TextField';
import React from 'react';
const inputComponent = React.forwardRef((props, ref) => {
    return React.createElement("div", Object.assign({ style: { height: '100%' }, ref: ref }, props));
});
const JoinConversationInput = ({ children, selectProps, innerRef, innerProps }) => {
    return (React.createElement(TextField, Object.assign({ variant: "standard", fullWidth: true, InputProps: {
            inputComponent,
            inputProps: Object.assign(Object.assign({ ref: innerRef, children }, innerProps), { style: {
                    width: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: selectProps.landing && selectProps.autoFocus ? -10 : 2,
                } }),
            disableUnderline: true,
        }, InputLabelProps: { shrink: true } }, selectProps.textFieldProps, { select: false, "data-qa": "searchAvaRoomInput" })));
};
export default JoinConversationInput;
