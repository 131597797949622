var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fab, Menu, MenuItem, Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Phone from '../../../assets/icons/phone.png';
import { useNotifications } from '../../hooks/useNotifications';
import { selectTwilioCalls } from '../../selectors/legacy-conversation';
import { selectAvaId } from '../../selectors/userProfile';
import { calls as api } from '../../services/api/ava';
import { useAppSelector } from '../../store/store';
const useStyles = makeStyles({
    root: { marginLeft: 30, marginRight: 30 },
    fab: { width: 44, height: 44, boxShadow: 'none', color: 'white' },
});
export default function CallList() {
    const classes = useStyles();
    const twilio = useAppSelector(selectTwilioCalls);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const avaId = useAppSelector(selectAvaId);
    const { notify } = useNotifications();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const calls = Array.from(Object.keys(twilio || {})).map((key) => (Object.assign({ sid: key }, twilio[key])));
    const endCall = (sid) => __awaiter(this, void 0, void 0, function* () {
        setAnchorEl(null);
        try {
            yield api.terminateCall({ avaId, sid });
            notify('Call ended.');
        }
        catch (err) {
            notify(err.message, { variant: 'error' });
        }
    });
    const theme = useTheme();
    return (React.createElement("div", { className: classes.root }, calls
        .filter((call) => call.status === 'in-progress')
        .map((call, i) => (
    // eslint-disable-next-line
    React.createElement("div", { key: `${call.to}-${i}` },
        React.createElement(ClickAwayListener, { onClickAway: () => setAnchorEl(null) },
            React.createElement(React.Fragment, null,
                React.createElement(Tooltip, { disableInteractive: true, title: call.to },
                    React.createElement(Fab, { className: classes.fab, onClick: handleClick, style: {
                            backgroundColor: theme.palette.ava.green,
                        } },
                        React.createElement("img", { src: Phone, alt: "phone", height: "20", className: classes.fabImg }))),
                React.createElement(Menu, { id: "simple-menu", anchorEl: anchorEl, open: Boolean(anchorEl) },
                    React.createElement(MenuItem, { onClick: () => endCall(call.sid) }, "End call")))))))));
}
