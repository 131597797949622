import CampaignIcon from '@mui/icons-material/Campaign';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { CircularProgress, Collapse, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectCurrentSelectedVoice, selectIsTtsLoading, selectTtsGender } from '../../selectors/textToSpeech';
import { setDisplayedItemsCount } from '../../store/slices/textToSpeech';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getImageNameForVoice } from '../../utils/textToSpeech';
import TtsImage from './TtsImage';
import { TtsPopoverSelection } from './TtsPopoverSelection';
import { TtsSelectedVoice } from './TtsSelectedVoice';
import { TtsSelector } from './TtsSelector';
const useStyles = makeStyles((theme) => ({
    wrapperInner: {
        display: 'flex',
        flexDirection: 'column',
    },
    text: {
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        fontWeight: 700,
        fontSize: 16,
        marginLeft: 8,
    },
}));
export const TtsDropdownOrPopover = ({ isDropdown }) => {
    var _a;
    const { t } = useTranslation();
    const classes = useStyles();
    const genderPreference = useAppSelector(selectTtsGender);
    const [showVoices, setShowVoices] = useState(genderPreference !== undefined);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const currentSelectedVoice = useAppSelector(selectCurrentSelectedVoice);
    const imageName = (_a = getImageNameForVoice(currentSelectedVoice)) !== null && _a !== void 0 ? _a : '';
    const isTtsLoading = useAppSelector(selectIsTtsLoading);
    const dispatch = useAppDispatch();
    useEffect(() => {
        // we want to only update this if gender choice changes
        if (genderPreference !== undefined) {
            setShowVoices(true);
        }
        else {
            setShowVoices(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [genderPreference]);
    if (isDropdown)
        return (React.createElement("div", null,
            React.createElement(ListItemButton, { onClick: () => {
                    setIsDropdownOpen(!isDropdownOpen);
                    if (isDropdown)
                        dispatch(setDisplayedItemsCount(2));
                } },
                React.createElement(ListItemIcon, null, currentSelectedVoice && !isTtsLoading ? (React.createElement(TtsImage, { imageName: imageName, imageAltText: currentSelectedVoice.name })) : (React.createElement(CampaignIcon, null))),
                React.createElement("div", { style: { width: '100%', display: 'flex', flexDirection: 'column' } },
                    React.createElement(ListItemText, null,
                        React.createElement(Typography, { fontSize: 15 }, t('options.selectVoice'))),
                    isTtsLoading ? (React.createElement("div", { style: { display: 'flex', justifyContent: 'center', width: '100%' } },
                        React.createElement(CircularProgress, { sx: { color: (theme) => theme.palette.ava.blue2 }, size: 24 }))) : (currentSelectedVoice && React.createElement(TtsSelectedVoice, { isDropdown: true, currentSelectedVoice: currentSelectedVoice }))),
                React.createElement(ListItemIcon, { style: { minWidth: 0, marginLeft: 0 } }, isDropdownOpen ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null))),
            React.createElement(Collapse, { classes: { wrapperInner: classes.wrapperInner }, in: isDropdownOpen },
                React.createElement(TtsSelector, { shouldStopAudio: !isDropdownOpen, isToggle: true, closeMenu: () => setIsDropdownOpen(false), showVoices: showVoices }))));
    else
        return React.createElement(TtsPopoverSelection, { showVoices: showVoices });
};
