import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggled';
import AvatarLogo from '../../components/Icons/AvatarLogo';
import { selectAvailableMicrophones } from '../../selectors/audioV2';
import { selectAutoTranslateActive } from '../../selectors/avaTranslate';
import { selectSpeakersMap, selectTranscript } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
import { getOS } from '../../utils';
import { canShowFlagEmoji } from '../../utils/platform';
import { getText } from '../../utils/scribeUtils';
import EditProfileModal from './EditProfileModal';
const TranscriptContainer = styled('div')((theme) => ({
    marginBottom: 5,
    color: theme.palette.text.primary,
}));
const FinalTranscript = (props) => {
    var _a;
    const { avaId, tid, userName, features, fontSize, lang, theme, electronCaptionMode } = props;
    const autoTranslateActive = useAppSelector(selectAutoTranslateActive);
    const availableMics = useAppSelector(selectAvailableMicrophones);
    const transcript = useAppSelector((state) => selectTranscript(state, tid));
    const speaker = (_a = useAppSelector(selectSpeakersMap)) === null || _a === void 0 ? void 0 : _a[transcript.author];
    const { t } = useTranslation();
    if (!getText(transcript, lang)) {
        return null;
    }
    const micOfCurrentTranscript = availableMics &&
        availableMics.find((mic) => mic.name === transcript.trackName || mic.label === transcript.trackName);
    const isInternalAudio = !!(speaker &&
        speaker.avaId === avaId &&
        transcript.trackName &&
        (transcript.trackName.startsWith('Ava Computer Audio') || (micOfCurrentTranscript === null || micOfCurrentTranscript === void 0 ? void 0 : micOfCurrentTranscript.isInternal)));
    let color = theme.palette.error.light;
    if (speaker && speaker.theme) {
        const { theme: speakerTheme } = speaker;
        color = speakerTheme[`${theme.palette.mode}_theme`].text_color;
    }
    if (speaker && (speaker.avaId === avaId || speaker.id === avaId)) {
        color =
            theme.palette.mode === 'light'
                ? theme.palette.ava.navyGroup.convoText
                : theme.palette.ava.navyGroup.convoTextDark;
        if (isInternalAudio) {
            color = theme.palette.ava.green;
        }
    }
    if (theme.palette.mode === 'dark' && color === '#000000') {
        color = '#FFFFFF';
    }
    let authorName = transcript.author;
    if (speaker && speaker.userName) {
        authorName = speaker.userName;
    }
    if (speaker && (speaker.avaId === avaId || speaker.id === avaId)) {
        authorName = t('transcripts.you');
        if (userName === 'web guest') {
            authorName += t('transcripts.tapToSetYourName');
        }
        if (isInternalAudio) {
            authorName = t('transcripts.internalAudioAuthorName');
        }
    }
    return (React.createElement(React.Fragment, null, React.createElement(TranscriptContainer, { style: {
            lineHeight: `${fontSize} px`,
            fontSize: `${fontSize} px`,
            WebkitAppRegion: getOS() !== 'Mac' ? 'no-drag' : undefined,
        } },
        React.createElement(Grid, { container: true, alignItems: "center", wrap: "nowrap", style: {
                marginTop: transcript.authorShow && speaker && speaker.userAvatar !== '' && !isInternalAudio
                    ? `${fontSize / 5} `
                    : '0',
            } },
            React.createElement(Hidden, { smDown: true },
                React.createElement(Grid, { item: true, xs: "auto", style: {
                        flexBasis: 0,
                        marginRight: !electronCaptionMode ? '5px' : 'initial',
                    } },
                    !electronCaptionMode && transcript.authorShow && speaker && speaker.userAvatar && !isInternalAudio && (React.createElement(Avatar, { key: "avatar", src: speaker.userAvatar, alt: "speaker_photo", style: {
                            width: fontSize,
                            height: fontSize,
                            marginTop: fontSize / 5,
                        } })),
                    !electronCaptionMode &&
                        transcript.authorShow &&
                        speaker &&
                        !speaker.userAvatar &&
                        !isInternalAudio && React.createElement(AvatarLogo, { color: color, width: fontSize * 2, height: fontSize * 2 }))),
            React.createElement(Grid, { item: true, xs: true, css: { lineHeight: 1 } },
                transcript.authorShow && (React.createElement(Toggle, null, ({ on, getTogglerProps, setOff }) => {
                    var _a;
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Typography, Object.assign({ variant: "h5", style: {
                                color: speaker
                                    ? (_a = speaker.theme) === null || _a === void 0 ? void 0 : _a[`${theme.palette.mode} _theme`].text_color
                                    : theme.palette.error.light,
                                fontWeight: 600,
                                fontSize,
                                outline: 'none',
                                cursor: speaker && speaker.id === avaId && !isInternalAudio && 'pointer',
                            } }, getTogglerProps()),
                            React.createElement("strong", { key: "name" }, authorName),
                            autoTranslateActive && (React.createElement(ReactCountryFlag, { style: {
                                    height: !canShowFlagEmoji() ? 32 : undefined,
                                    width: !canShowFlagEmoji() ? 32 : undefined,
                                }, svg: !canShowFlagEmoji(), countryCode: transcript.speechLang }))),
                        speaker && speaker.id === avaId && !isInternalAudio && (React.createElement(EditProfileModal, { open: on, close: setOff }))));
                })),
                React.createElement("div", { style: {
                        opacity: (features === null || features === void 0 ? void 0 : features['dev-mode']) ? 0.7 : 1,
                        // $FlowFixMe
                        fontWeight: transcript.highlighted ? 600 : '',
                    } }, getText(transcript, lang)))))));
};
export default FinalTranscript;
