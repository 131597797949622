import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import platform from 'platform';
import React, { useEffect, useRef, useState } from 'react';
import { selectConversationEnded } from '../../selectors/conversation';
import { selectIsWordBeingEdited } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import { selectElementContents, setEndOfContenteditable, setStartOfContenteditable } from '../../utils/domUtils';
import keycodes from '../../utils/keycodes';
import { capitalizeWord, endPunctuation, removeEndPunctuation, toggleCapitalizeWord } from '../../utils/scribeUtils';
const useStyles = makeStyles(() => ({
    blocInput: {
        display: 'inline',
        padding: '1px',
        outline: 'none',
        cursor: 'pointer',
        whiteSpace: 'pre-wrap',
        '&::selection': {
            background: 'transparent',
        },
    },
}));
const isMac = platform.os.family === 'OS X';
const ScribeWordInput = (props) => {
    const { highlightTranscript, insertUpdateMutation, scribeDeselectTranscriptWord, scribeJumpToBlocEnd, scribeJumpToBlocStart, scribeJumpToNextBloc, scribeJumpToNextHightlightedBloc, scribeJumpToPreviousBloc, scribeSelectTranscriptWord, scribeUpdateInputValue, scribeWordEditSetStarted, selectedTranscriptCaretPosition, selectedTranscriptIndex, selectedWordIndex, selectedWordInputValue, } = props;
    const input = useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const [noInteractionYet, setNoInteractionYet] = useState(true);
    const [noInputChangeYet, setNoInputChangeYet] = useState(true);
    const wordBeingEdited = useAppSelector(selectIsWordBeingEdited);
    const conversationEnded = useAppSelector(selectConversationEnded);
    useEffect(() => {
        if (!input.current)
            return;
        if (input.current.innerText === '' && selectedWordInputValue.trim() !== '') {
            input.current.innerText = selectedWordInputValue.replace(/`/g, '');
            selectElementContents(input.current);
        }
    }, [selectedWordInputValue]);
    useEffect(() => {
        if (input.current && !conversationEnded) {
            setEndOfContenteditable(input.current);
            if (!wordBeingEdited && selectedTranscriptCaretPosition === 'all') {
                selectElementContents(input.current);
            }
            if (selectedTranscriptCaretPosition === 'first') {
                setStartOfContenteditable(input.current);
            }
        }
    }, []);
    const handleClick = () => {
        setNoInteractionYet(false);
    };
    const handleKeyDown = (ev) => {
        if (!input.current)
            return;
        const key = keycodes(ev);
        if (key.RETURN && !key.CTRL && !key.META) {
            ev.preventDefault();
            ev.stopPropagation();
            scribeSelectTranscriptWord({
                index: selectedWordIndex,
                indexTranscript: selectedTranscriptIndex,
                direction: 1,
                skipCreateNextBloc: false,
            }, { src: 'RETURN_KEYPRESS' });
        }
        else if (key.CTRL && key.SHIFT && key.LEFT_ARROW) {
            scribeJumpToBlocStart();
        }
        else if ((!isMac && key.CTRL && key.ALT && key.DOWN_ARROW) || (isMac && key.META && key.DOWN_ARROW)) {
            scribeJumpToNextHightlightedBloc({
                transcriptId: selectedTranscriptIndex,
                direction: 1,
            });
        }
        else if ((!isMac && key.CTRL && key.ALT && key.UP_ARROW) || (isMac && key.META && key.UP_ARROW)) {
            scribeJumpToNextHightlightedBloc({
                transcriptId: selectedTranscriptIndex,
                direction: -1,
            });
        }
        else if (key.CTRL && key.SHIFT && key.RIGHT_ARROW) {
            scribeJumpToBlocEnd();
        }
        else if (key.ALT && key.UP_ARROW) {
            scribeJumpToPreviousBloc({
                indexTranscript: selectedTranscriptIndex,
                firstWord: true,
            });
        }
        else if (key.ALT && key.DOWN_ARROW) {
            scribeJumpToNextBloc({
                transcriptId: selectedTranscriptIndex,
                skipCreateNextBloc: true,
            });
        }
        else if ((!isMac && key.CTRL && key.ALT && key.LEFT_ARROW) || (isMac && key.META && key.LEFT_ARROW)) {
            scribeJumpToBlocStart();
        }
        else if ((!isMac && key.CTRL && key.ALT && key.RIGHT_ARROW) || (isMac && key.META && key.RIGHT_ARROW)) {
            scribeJumpToBlocEnd();
        }
        else if ((!isMac && key.CTRL && key.h) || (isMac && key.META && key.h)) {
            ev.stopPropagation();
            ev.preventDefault();
            ev.nativeEvent.stopImmediatePropagation();
            highlightTranscript(selectedTranscriptIndex);
        }
        else if ((!isMac && key.CTRL && key.BACKSPACE) || (isMac && key.META && key.BACKSPACE)) {
            // Do nothing here
        }
        else if (key.BACKSPACE) {
            // This is already "handled" by handleChange and we dont want it to bubble up to the transcript level
            ev.stopPropagation();
            ev.nativeEvent.stopImmediatePropagation();
        }
        else if ((!isMac && key.CTRL && ev.key === 'b') || (isMac && key.META && ev.key === 'b')) {
            ev.preventDefault();
            ev.stopPropagation();
            input.current.innerText = capitalizeWord(input.current.innerText || '');
            scribeUpdateInputValue(input.current.innerText || '', 'all');
            selectElementContents(input.current);
            insertUpdateMutation({
                selectedWordIndex,
                selectedTranscriptIndex,
                text: input.current.innerText,
                src: `press_"${ev.key}"`,
            });
        }
        else if (endPunctuation.includes(ev.key) && noInputChangeYet && input.current.innerText !== '') {
            ev.preventDefault();
            ev.stopPropagation();
            input.current.innerText = `${removeEndPunctuation(input.current.innerText || '', ev.key)}${ev.key}`;
            scribeUpdateInputValue(input.current.innerText || '', 'all');
            selectElementContents(input.current);
            insertUpdateMutation({
                selectedWordIndex,
                selectedTranscriptIndex,
                text: input.current.innerText,
                src: `press_"${ev.key}"`,
            });
        }
        else if (ev.key === ' ' && noInputChangeYet && noInteractionYet) {
            ev.preventDefault();
            ev.stopPropagation();
            input.current.innerText = `${input.current.innerText || ''} `;
            scribeUpdateInputValue(input.current.innerText || '', 'all');
            selectElementContents(input.current);
            insertUpdateMutation({
                selectedWordIndex,
                selectedTranscriptIndex,
                text: input.current.innerText,
                src: `press_space`,
            });
        }
        else if ((!isMac && key.CTRL && ev.key === 'c') || (isMac && key.META && ev.key === 'c')) {
            ev.preventDefault();
            ev.stopPropagation();
            input.current.innerText = `${toggleCapitalizeWord(input.current.innerText || '')}`;
            scribeUpdateInputValue(input.current.innerText || '', 'all');
            selectElementContents(input.current);
            insertUpdateMutation({
                selectedWordIndex,
                selectedTranscriptIndex,
                text: input.current.innerText,
                src: `press_"${ev.key}"`,
            });
            scribeSelectTranscriptWord({
                index: selectedWordIndex,
                indexTranscript: selectedTranscriptIndex,
                direction: 1,
                skipCreateNextBloc: false,
            }, { src: 'CAPITALIZE' });
        }
        else if ((!isMac && key.CTRL && ev.key === 'd') || (isMac && key.META && ev.key === 'd')) {
            ev.preventDefault();
            ev.stopPropagation();
            input.current.innerText = `${removeEndPunctuation(input.current.innerText || '')}`;
            scribeUpdateInputValue(input.current.innerText || '', 'all');
            selectElementContents(input.current);
            insertUpdateMutation({
                selectedWordIndex,
                selectedTranscriptIndex,
                text: input.current.innerText,
                forceUncapitalize: false,
                src: `press_"${ev.key}"`,
            });
        }
        setNoInteractionYet(false);
    };
    const handleChange = () => {
        if (noInputChangeYet) {
            scribeWordEditSetStarted({
                indexTranscript: selectedTranscriptIndex,
                index: selectedWordIndex,
            });
        }
        setNoInputChangeYet(false);
        if (!input.current)
            return;
        const text = !input.current.innerText || input.current.innerText === '\n' ? '' : input.current.innerText;
        scribeUpdateInputValue(text);
        if (text === '') {
            scribeDeselectTranscriptWord({}, { src: 'BACKSPACE_KEYPRESS' });
            scribeSelectTranscriptWord({
                index: selectedWordIndex,
                indexTranscript: selectedTranscriptIndex,
                skipCommit: true,
                skipJumpNextBloc: true,
            });
        }
        // TODO: remove the must include space condition
        // to have letter by letter mutation
        // Only when backend (ot) will be readu
        if (text.includes(' ')) {
            insertUpdateMutation({
                selectedWordIndex,
                selectedTranscriptIndex,
                text,
                src: 'SPACE_KEYPRESS',
            });
        }
    };
    return (React.createElement("span", null,
        React.createElement("div", { className: classes.blocInput, style: {
                background: theme.palette.secondary[theme.palette.mode === 'light' ? 'dark' : 'light'],
                color: theme.palette.primary[theme.palette.mode],
            }, onClick: handleClick, ref: input, contentEditable: "true", suppressContentEditableWarning: true, onInput: handleChange, onKeyDown: handleKeyDown }),
        ' '));
};
export default ScribeWordInput;
