import LanguageIcon from '@mui/icons-material/Language';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageButton from '../../components/Buttons/LanguageButton';
import DarkModeIcon from '../../components/Icons/DarkMode';
import DarkModeSwitch from '../../components/Switches/DarkModeSwitch';
import SaveTranscriptsSwitch from '../../components/Switches/SaveTranscriptsSwitch';
import BoostSettings from './boost/BoostSettings';
import SectionContainer from './SectionContainer';
import SectionDivider from './SectionDivider';
import SectionTitle from './SectionTitle';
const useStyles = makeStyles((theme) => ({
    conversations: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
        marginBottom: 20,
        maxWidth: 650,
    },
    itemNameContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 8,
        },
    },
    icon: {
        width: 28,
        height: 28,
    },
    itemName: {
        marginLeft: 16,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 8,
        },
    },
    text: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
}));
const General = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionContainer, { className: classnames(classes.conversations) },
            React.createElement(SectionTitle, null, t('accountSettings.general.conversations')),
            React.createElement(SectionDivider, null),
            React.createElement("div", { className: classes.item },
                React.createElement("div", { className: classes.itemNameContainer },
                    React.createElement("span", { className: classes.text }, t('accountSettings.general.saveTranscriptsAutomatically')),
                    React.createElement(SaveTranscriptsSwitch, null))),
            React.createElement("div", { className: classes.item },
                React.createElement("div", { className: classes.itemNameContainer },
                    React.createElement("div", null,
                        React.createElement(LanguageIcon, { className: classes.icon }),
                        React.createElement("span", { className: classes.itemName }, t('accountSettings.general.language'))),
                    React.createElement(LanguageButton, null))),
            React.createElement("div", { className: classes.item },
                React.createElement("div", { className: classes.itemNameContainer },
                    React.createElement("div", null,
                        React.createElement(DarkModeIcon, null),
                        React.createElement("span", { className: classes.itemName }, t('accountSettings.general.darkMode'))),
                    React.createElement(DarkModeSwitch, null)))),
        React.createElement(SectionContainer, null,
            React.createElement(SectionTitle, null, t('accountSettings.general.audio')),
            React.createElement(SectionDivider, null),
            React.createElement(BoostSettings, null))));
};
export default General;
