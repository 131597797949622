import { useTheme } from '@mui/material/styles';
import React from 'react';
function PasswordStrengthIndicator({ passwordStrength }) {
    const theme = useTheme();
    const getColor = (index) => {
        switch (passwordStrength) {
            case 'weak':
                return index === 1 || index === undefined ? '#FF9C39' : theme.palette.ava.grey5;
            case 'good':
                return index ? (index <= 2 ? theme.palette.ava.scribe2 : theme.palette.ava.grey5) : theme.palette.ava.scribe2;
            case 'strong':
                return theme.palette.ava.blue2;
            default:
                return theme.palette.ava.grey5;
        }
    };
    return (React.createElement("div", { style: { width: '100%', marginTop: -16, marginBottom: 4 } },
        React.createElement("div", { style: { display: 'flex', marginTop: 20 } }, [1, 2, 3].map((index) => (React.createElement("div", { key: index, style: {
                width: '33%',
                height: 10,
                backgroundColor: getColor(index),
                borderRadius: 5,
                marginRight: index !== 3 ? 2 : 0,
            } })))),
        React.createElement("div", { style: { textAlign: 'right', marginTop: 5, color: getColor() } }, passwordStrength.charAt(0).toUpperCase() + passwordStrength.slice(1))));
}
export default PasswordStrengthIndicator;
