var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import 'react-phone-input-2/lib/material.css';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import RoundedButton from '../../components/Buttons/RoundedButton';
import { confirmationResult, signInWithPhoneNumber } from '../../store/slices/auth';
import LoginTextField from './LoginTextField';
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '-2%',
        width: '100%',
    },
    form: { width: '100%' },
    input: {
        height: 48,
        padding: '0px 8px',
        borderRadius: 8,
        border: `1px solid ${theme.palette.ava.grey7}`,
        width: '100% !important',
        fontSize: 14,
        marginBottom: 10,
        boxShadow: '0px 2px 8px 0px rgba(74, 74, 74, 0.09)',
    },
    inputWrapper: {
        width: '100%',
    },
    continueButton: {
        width: '100%',
        boxShadow: '2px 2px 8px 0px rgba(112, 144, 176, 0.35)',
        margin: '5px 0px',
        background: theme.palette.ava.blue2,
        color: 'white',
        padding: '10px 24px',
        fontSize: 18,
        fontWeight: 600,
        '&:disabled': {
            color: 'white',
            opacity: '50%',
        },
        '&:hover': {
            background: theme.palette.ava.blue4,
        },
    },
    typeIn: {
        color: theme.palette.ava.grey5,
        marginBottom: '5%',
        textAlign: 'center',
    },
}));
function PhoneLogin() {
    const classes = useStyles();
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const { t } = useTranslation();
    useEffect(() => {
        let timer;
        if (error) {
            timer = setTimeout(() => {
                setError('');
            }, 2500);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [error]);
    const sendVerificationCode = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        const appVerifier = window.recaptchaVerifier;
        setLoading(true);
        // we received a bunch of spam auth attempts from indonesian numbers, so disallow them
        const isIndonesia = phone.startsWith('62');
        if (isIndonesia) {
            setLoading(false);
            setError(t('onboardingV2.errors.invalidPhoneNumber'));
            return;
        }
        try {
            const confirmationResult = yield signInWithPhoneNumber(`+${phone}`, appVerifier);
            window.confirmationResult = confirmationResult;
            setLoading(false);
            setStep(1);
        }
        catch (err) {
            setLoading(false);
            Sentry.captureException(err);
            if (err.code === 'auth/invalid-phone-number') {
                setError(t('onboardingV2.errors.invalidPhoneNumber'));
            }
        }
    });
    const confirmVerificationCode = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        setLoading(true);
        const response = yield confirmationResult(code);
        if (response === 'success') {
            return;
        }
        else if (response.code === 'auth/invalid-verification-code') {
            setLoading(false);
            setError(t('onboardingV2.errors.invalidCode'));
            Sentry.captureException(response);
        }
        else {
            setLoading(false);
            Sentry.captureException(response);
        }
    });
    const handleSubmit = useCallback((e) => {
        step === 0 ? sendVerificationCode(e) : confirmVerificationCode(e);
    }, [step, phone, code]);
    return (React.createElement("div", { className: classes.container },
        React.createElement(Typography, { className: classes.typeIn }, step === 0 ? t('onboardingV2.signin.typeIn') : t('onboardingV2.signin.inputCode')),
        React.createElement("form", { className: classes.form, noValidate: true },
            step === 0 ? (React.createElement(PhoneInput, { containerClass: classes.inputWrapper, country: "us", defaultErrorMessage: error, excludeCountries: ['id'], inputClass: classes.input, isValid: !error.length, onChange: (phoneNumber) => setPhone(phoneNumber), value: phone, inputStyle: { display: 'flex' }, onKeyDown: (event) => {
                    if (event.key === 'Enter' && phone.length > 8) {
                        handleSubmit(event);
                    }
                }, placeholder: "+1-555-123-4567" })) : (React.createElement(LoginTextField, { size: "medium", type: "number", autoFocus: true, name: "code", error: !!error.length, helperText: error, onChange: (codeValue) => setCode(codeValue), onKeyDown: (event) => {
                    if (event.key === 'Enter' && code.length === 6) {
                        handleSubmit(event);
                    }
                }, label: '', placeholder: '000000', value: code })),
            React.createElement(RoundedButton, { type: "submit", onClick: (e) => handleSubmit(e), id: "phone-sign-in-button", disabled: (step === 0 && phone.length < 8) || (step === 1 && code.length !== 6) || loading, className: classes.continueButton }, t('onboardingV2.continue')))));
}
export default PhoneLogin;
