import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SnackbarWithCountdown from '../../components/SnackbarWithCountdown';
import usePrevious from '../../hooks/usePrevious';
import { selectConversationEnded, selectIsInConversation } from '../../selectors/conversation';
import { selectStatusReason, selectStatusRestrictionsConvoWarningCountdownTimerMs, selectStatusStateValue, } from '../../selectors/legacy-conversation';
import { selectOrganization, selectSubscription } from '../../selectors/userProfile';
import LocalStorage, { STORAGE_KEYS } from '../../services/localStorage';
import { setEndedDueToConvoRestrictedSession } from '../../store/slices/conversationEnd';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getPlanType } from '../../utils/status';
const useStyles = makeStyles({
    snackbarCountdown: {
        position: 'absolute',
        top: 10,
        left: 10,
    },
});
const RoomEndingWarningSnackbar = ({ variant }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [roomEndingWarningBegin, setRoomEndingWarningBegin] = useState(0);
    const [roomEndingWarningCountdown, setRoomEndingWarningCountdown] = useState(0);
    const [roomEndingWarningProlongUrl, setRoomEndingWarningProlongUrl] = useState('');
    const [appSubmittedTypeform, setAppSubmittedTypeform] = useState({});
    const subscription = useAppSelector(selectSubscription);
    const statusRestrictionsConvoWarningCountdownTimerMs = useAppSelector(selectStatusRestrictionsConvoWarningCountdownTimerMs);
    const statusReason = useAppSelector(selectStatusReason);
    const statusStateValue = useAppSelector(selectStatusStateValue);
    const conversationEnded = useAppSelector(selectConversationEnded);
    const isInConversation = useAppSelector(selectIsInConversation);
    const organization = useAppSelector(selectOrganization);
    useEffect(() => {
        if (conversationEnded) {
            dispatch(setEndedDueToConvoRestrictedSession(false));
            setRoomEndingWarningBegin(0);
            setRoomEndingWarningCountdown(0);
            setRoomEndingWarningProlongUrl('');
            setAppSubmittedTypeform({});
        }
    }, [conversationEnded]);
    const prevIsInConversation = usePrevious(isInConversation);
    useEffect(() => {
        if (!isInConversation && prevIsInConversation) {
            setRoomEndingWarningBegin(0);
        }
    }, [isInConversation]);
    useEffect(() => {
        if (statusReason === 'convo-restricted-session-warning') {
            const params = {
                lang: i18n.language,
                src: 'timeout',
                med: window.isElectron ? 'cc' : 'webapp',
                mkt: '',
            };
            switch (subscription === null || subscription === void 0 ? void 0 : subscription.ongoingSubscription) {
                case 'Campus':
                    params.mkt = 'edu';
                    break;
                case 'Pro':
                    params.mkt = 'hr';
                    break;
                case 'Premium':
                case 'Free':
                    params.mkt = 'ind';
                    break;
                default:
                    break;
            }
            let url = `https://ava-me.typeform.com/to/${i18n.language === 'fr' ? 'R8L5rC' : 'q2n11h'}${Object.keys(params).reduce((acc, curr) => `${acc}${curr}=${params[curr]}&`, '?')}`;
            url = url.substring(0, url.length - 1);
            url += `#userid=${LocalStorage.get(STORAGE_KEYS.AVA_ID) || ''}`;
            setRoomEndingWarningBegin(Date.now());
            setRoomEndingWarningCountdown(statusRestrictionsConvoWarningCountdownTimerMs / 1000 || 300);
            setRoomEndingWarningProlongUrl(url);
            setAppSubmittedTypeform({
                channel: 'inbound',
                source: 'timeout',
                market: params.mkt === 'ind' ? 'individual' : params.mkt,
                organization_name: organization === null || organization === void 0 ? void 0 : organization.name,
                plan: getPlanType(subscription),
            });
        }
    }, [statusReason]);
    useEffect(() => {
        if (statusStateValue === 'ended' && statusReason === 'room-ended-due-to-convo-restricted-session') {
            dispatch(setEndedDueToConvoRestrictedSession(true));
        }
    }, [statusStateValue]);
    return (React.createElement(SnackbarWithCountdown, { classes: variant === 'cc'
            ? {
                anchorOriginTopLeft: classes.snackbarCountdown,
            }
            : undefined, message: t('snackbar.convoRestrictedSessionWarning'), countdown: roomEndingWarningCountdown, countdownBegin: roomEndingWarningBegin, additionalActionMessage: t('snackbar.convoRestrictedSessionProlong'), additionalActionUrl: roomEndingWarningProlongUrl, appSubmittedTypeform: appSubmittedTypeform }));
};
export default RoomEndingWarningSnackbar;
