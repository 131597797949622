import React, { useRef } from 'react';
import { generateRandomId } from '../../utils';
const Mic = ({ width, height }) => {
    const randomId = useRef(generateRandomId());
    return (React.createElement("svg", { width: width || '64', height: height || '72', viewBox: "0 0 64 72", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { filter: `url(#filter0_${randomId.current})` },
            React.createElement("path", { d: "M32 64C47.464 64 60 51.464 60 36C60 20.536 47.464 8 32 8C16.536 8 4 20.536 4 36C4 51.464 16.536 64 32 64Z", fill: `url(#paint0_linear_${randomId.current}` })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M26.7077 37.763C26.7077 40.8299 29.1262 43.315 32.1102 43.315C35.0942 43.315 37.5127 40.8299 37.5127 37.763V26.6598C37.5127 23.593 35.0942 21.1069 32.1102 21.1069C29.1262 21.1069 26.7077 23.593 26.7077 26.6598V37.763ZM23.0603 32.7223V37.5751C23.0603 42.4014 26.642 46.3621 31.2082 46.7743V51.4881H25.8624V53.2753H38.3557V51.4881H33.0099V46.7509C37.4685 46.2308 40.9326 42.3216 40.9326 37.5751V32.7223H39.3071V38.0844C39.3071 42.0324 36.0819 45.2333 32.1038 45.2333C28.1258 45.2333 24.9005 42.0324 24.9005 38.0844V32.7223H23.0603Z", fill: "#1B367C" }),
        React.createElement("defs", null,
            React.createElement("filter", { id: `filter0_${randomId.current}`, x: "0", y: "0", width: "64", height: "72", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.439216 0 0 0 0 0.564706 0 0 0 0 0.690196 0 0 0 0.4 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_444:382" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "-4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "effect1_dropShadow_444:382", result: "effect2_dropShadow_444:382" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect2_dropShadow_444:382", result: "shape" })),
            React.createElement("linearGradient", { id: `paint0_linear_${randomId.current}`, x1: "9.9564", y1: "19.9147", x2: "51.0626", y2: "56.8507", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#F7FBFF" }),
                React.createElement("stop", { offset: "1", stopColor: "#D5D7D9" })))));
};
export default Mic;
