import { makeStyles } from '@mui/styles';
import React from 'react';
import { useAppSelector } from '../store/store';
const useStyles = makeStyles({
    dot: {
        animation: `1s $blink infinite`,
        '&:nth-child(1)': {
            animationDelay: '0ms',
        },
        '&:nth-child(2)': {
            animationDelay: '250ms',
        },
        '&:nth-child(3)': {
            animationDelay: '500ms',
        },
    },
    '@keyframes blink': {
        '50%': {
            color: 'transparent',
        },
    },
});
const AsrQueueingMessageLoader = ({ transcriptId }) => {
    const classes = useStyles();
    const value = useAppSelector((state) => { var _a, _b, _c; return !!((_c = (_b = (_a = state.scribeConversation) === null || _a === void 0 ? void 0 : _a.transcripts[transcriptId]) === null || _b === void 0 ? void 0 : _b.mutationsQueuedByHumanMutation) === null || _c === void 0 ? void 0 : _c.length); });
    return (React.createElement("span", null, value && (React.createElement("span", null,
        React.createElement("span", { className: classes.dot }, "."),
        React.createElement("span", { className: classes.dot }, "."),
        React.createElement("span", { className: classes.dot }, ".")))));
};
export default AsrQueueingMessageLoader;
