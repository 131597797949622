import classnames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createNewConversation } from '../../../actions';
import CloseButton from '../../../components/CCMode/CloseButton';
import ExpandButton from '../../../components/CCMode/ExpandButton';
import StartConversationButton from '../../../components/CCMode/StartConversationButton';
import { selectShowBubbleAboveCCMode } from '../../../selectors/ccMode';
import { setCCMode, setShowBubbleAboveCCModeDebounced } from '../../../store/slices/ccMode';
import { useAppSelector } from '../../../store/store';
import useStyles from './HomeView.styles';
const HomeView = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showBubbleAboveCCMode = useAppSelector(selectShowBubbleAboveCCMode);
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classnames(classes.buttons, {
                [classes.buttonsVisible]: showBubbleAboveCCMode,
            }) },
            React.createElement(ExpandButton, { onClick: () => navigate('/web/transcript') }),
            React.createElement(CloseButton, { onClick: () => { var _a; return (_a = window.electronIPC) === null || _a === void 0 ? void 0 : _a.sendCCMode('close'); } })),
        React.createElement(StartConversationButton, { onClick: () => {
                dispatch(setCCMode('conversation'));
                dispatch(createNewConversation());
            }, onMouseEnter: () => dispatch(setShowBubbleAboveCCModeDebounced(true)) })));
};
export default HomeView;
