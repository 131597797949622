import { Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import female from '../../../assets/icons/female.svg';
import male from '../../../assets/icons/male.svg';
import neutral from '../../../assets/icons/neutral.svg';
import PhraseMarkdownText from '../../components/PhraseMarkdownText';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { setTtsGenderThunk } from '../../store/slices/textToSpeech';
import { useAppDispatch, useAppSelector } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    fab: {
        backgroundColor: 'transparent',
        padding: '16px 20px',
        boxShadow: '0px 4px 8px 0px rgba(112, 144, 176, 0.20)',
        borderRadius: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 180,
        width: 170,
        gap: 16,
        ['@media (max-width: 700px)']: {
            width: 'auto',
        },
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.ava.navy : theme.palette.ava.navy5,
            cursor: 'pointer',
        },
    },
    popupMenu: {
        backgroundColor: 'transparent',
        boxShadow: '0px 4px 8px 0px rgba(112, 144, 176, 0.20)',
        padding: 10,
        borderRadius: 20,
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 80,
        width: '100%',
        gap: 8,
        ['@media (max-width: 700px)']: {
            width: 'auto',
        },
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.ava.navy : theme.palette.ava.navy5,
            cursor: 'pointer',
        },
    },
    popupMenuCC: {
        backgroundColor: 'rgba(27, 54, 124, 0.60)',
        boxShadow: 'none',
        height: 116,
        display: 'flex',
        padding: 0,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: 8,
        flex: '1 0 0',
        '&:hover': {
            background: theme.palette.ava.blue2,
            cursor: 'pointer',
        },
    },
    menuFab: {
        backgroundColor: 'transparent',
        padding: 0,
        boxShadow: 'none',
        borderRadius: 16,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 80,
        width: '100%',
        gap: 20,
        ['@media (max-width: 700px)']: {
            width: 'auto',
        },
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.ava.navy : theme.palette.ava.navy5,
        },
    },
    menuGenderImage: {
        width: 48,
        height: 48,
        marginRight: 20,
        order: 1,
    },
    popupMenuGenderImage: {
        marginRight: 0,
        height: 'auto',
        width: 48,
    },
    genderImage: {
        width: 80,
        height: 80,
        order: 0,
        marginRight: 0,
    },
    fabPopupText: {
        fontWeight: 600,
        textAlign: (props) => (props.electronCaptionMode ? 'center' : 'left'),
        color: theme.palette.mode === 'dark' ? 'white' : theme.palette.ava.deepBlue,
        fontSize: 16,
        paddingLeft: (props) => (props.electronCaptionMode ? 0 : 20),
        gap: 16,
        textTransform: 'none',
        width: '100%',
        ['@media (max-width: 700px)']: {
            fontSize: '1em',
        },
        order: 0,
    },
    fabMenuText: {
        fontWeight: 600,
        textAlign: 'left',
        color: theme.palette.mode === 'dark' ? 'white' : theme.palette.ava.deepBlue,
        fontSize: 20,
        paddingLeft: 20,
        gap: 16,
        textTransform: 'none',
        width: '50%',
        ['@media (max-width: 700px)']: {
            fontSize: '1em',
        },
        order: 0,
    },
    fabText: {
        fontWeight: 600,
        textAlign: 'center',
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        paddingLeft: 0,
        fontSize: 18,
        gap: 16,
        textTransform: 'none',
        width: (props) => (props.redirect ? '100%' : '50%'),
        ['@media (max-width: 700px)']: {
            fontSize: '1em',
        },
        order: 1,
    },
}));
export const TtsGenderFab = ({ redirect, menu, buttonPopupMenu }) => {
    const { t } = useTranslation();
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const classes = useStyles({ menu, buttonPopupMenu, electronCaptionMode, redirect });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const fabClassName = classNames({
        [classes.popupMenuCC]: buttonPopupMenu && electronCaptionMode,
        [classes.popupMenu]: buttonPopupMenu && !electronCaptionMode,
        [classes.menuFab]: menu && !buttonPopupMenu,
        [classes.fab]: !menu && !buttonPopupMenu,
    });
    const genderImageClassName = classNames({
        [classes.popupMenuGenderImage]: buttonPopupMenu,
        [classes.menuGenderImage]: menu && !buttonPopupMenu,
        [classes.genderImage]: !menu && !buttonPopupMenu,
    });
    const fabTextClassName = classNames({
        [classes.fabPopupText]: buttonPopupMenu,
        [classes.fabMenuText]: menu && !buttonPopupMenu,
        [classes.fabText]: !menu && !buttonPopupMenu,
    });
    const handleFabClick = (gender) => {
        dispatch(setTtsGenderThunk(gender));
        if (redirect)
            navigate('/web/onboarding/profile/voice-selection');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Fab, { variant: "extended", className: fabClassName, onClick: () => handleFabClick('male') },
            React.createElement("img", { className: genderImageClassName, src: male, alt: t('profiling.genderSelection.male') }),
            React.createElement("div", { className: fabTextClassName },
                React.createElement(PhraseMarkdownText, { phraseKey: "profiling.genderSelection.male" }))),
        React.createElement(Fab, { variant: "extended", className: fabClassName, onClick: () => {
                handleFabClick('female');
            } },
            React.createElement("img", { className: genderImageClassName, src: female, alt: t('profiling.genderSelection.female') }),
            React.createElement("div", { className: fabTextClassName },
                React.createElement(PhraseMarkdownText, { phraseKey: "profiling.genderSelection.female" }))),
        React.createElement(Fab, { variant: "extended", className: fabClassName, onClick: () => {
                handleFabClick('neutral');
            } },
            React.createElement("img", { className: genderImageClassName, src: neutral, alt: t('profiling.genderSelection.neutral') }),
            React.createElement("div", { className: fabTextClassName },
                React.createElement(PhraseMarkdownText, { phraseKey: "profiling.genderSelection.neutral" })))));
};
