import MuiAppBar from '@mui/material/AppBar';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { getIsMac } from '../utils/electron';
const useStyles = makeStyles(() => ({
    appBar: {
        WebkitAppRegion: 'no-drag',
    },
}));
const AppBar = ({ className, style, position, children }) => {
    const classes = useStyles();
    let top = 0;
    if (window.isElectron) {
        const isMac = getIsMac();
        top = isMac ? 28 : 32;
    }
    return (React.createElement(MuiAppBar, { className: classnames(classes.appBar, className), position: position, style: Object.assign(Object.assign({}, style), { top }) }, children));
};
export default AppBar;
