var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Dialog, DialogActions, DialogContent, Popover, TextField, Tooltip, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AvaMic from '../../assets/icons/mic-ava.png';
import ScribeImg from '../../assets/images/scribe.svg';
import { useNotifications } from '../hooks/useNotifications';
import { useV1Socket } from '../hooks/useV1Socket';
import { selectFirebaseUser } from '../selectors/auth';
import { selectShowBubbleAboveCCMode } from '../selectors/ccMode';
import { selectElectronCaptionMode } from '../selectors/ui';
import { selectAvaId } from '../selectors/userProfile';
import { beginChatWithUser } from '../services/api/ava';
import { resetSelectedCaptions } from '../store/slices/conversation';
import { useAppDispatch, useAppSelector } from '../store/store';
import { differingKeys } from '../utils/object';
import { sendForceMute, sendTypeOnly, sendUpdateTranscriptOwnerMessage } from '../utils/ws-v1';
import AvatarLogo from './Icons/AvatarLogo';
import Transition from './Transition';
const participantPaperProps = {
    style: {
        marginTop: 8,
        marginLeft: 0,
    },
};
const useStyles = makeStyles((theme) => {
    return {
        badgeRed: {
            backgroundColor: 'red',
            marginTop: 5,
            marginRight: 5,
        },
        badgeGreen: {
            backgroundColor: 'green',
            marginTop: 5,
            height: 15,
            minWidth: 15,
            width: 15,
        },
        cancelScribeButton: {
            fontWeight: 600,
            padding: '6px 12px',
            textTransform: 'none',
        },
        cancelScribeButtonCCMode: {
            padding: 4,
            height: 32,
            background: 'rgba(0, 0, 0, 0.5)',
            backgroundBlendMode: 'overlay',
            backdropFilter: 'blur(50px)',
            borderRadius: 4,
            fontWeight: 400,
            WebkitAppRegion: 'no-drag',
            '&:hover': {
                background: theme.palette.ava.grey5,
            },
        },
        dot: {
            backgroundColor: 'green',
            marginTop: 5,
            marginRight: 5,
        },
        noBadge: {
            display: 'none',
        },
        micContainer: {
            backgroundColor: theme.palette.ava.blue,
            position: 'absolute',
            width: 20,
            height: 20,
            borderRadius: 10,
            textAlign: 'center',
            '&.muted': {
                backgroundColor: theme.palette.ava.grey,
            },
        },
        micAvatar: {
            bottom: 0,
            right: -2,
        },
        micAvatarLogo: {
            bottom: 2,
            right: -2,
        },
        avatar: {
            margin: 3,
            borderStyle: 'solid',
            color: 'white',
            fontSize: 26,
            borderWidth: 2,
        },
        big: {
            height: 86,
            width: 86,
        },
        listened: {
            borderRightColor: `${theme.palette.ava.green} !important`,
            borderBottomColor: `${theme.palette.ava.green} !important`,
            boxShadow: `0px 0px 5px 3px ${theme.palette.ava.green}`,
        },
        popoverMenuContainer: {
            display: 'flex',
            flexDirection: 'column',
            '& .MuiButton-root': {
                color: theme.palette.text.primary,
            },
        },
    };
});
// eslint-disable-next-line react/prefer-stateless-function
const ParticipantAvatar = React.memo((props) => {
    var _a, _b;
    const { audio, canEdit, canMute, currentUserIsHost, currentUserIsScribe, index, isScribe, listened, muted, numberOfConnections, scribeStatus, size, participant, } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [cancelScribePopoverOpen, setCancelScribePopoverOpen] = useState(false);
    const [participantPopoverOpen, setParticipantPopoverOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [userName, setUserName] = useState(props.participant ? props.participant.userName : '');
    const [title, setTitle] = useState(props.participant ? props.participant.title : '');
    const avatarRef = useRef(null);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const avaId = useAppSelector(selectAvaId);
    const dispatch = useAppDispatch();
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const showBubbleAboveCCMode = useAppSelector(selectShowBubbleAboveCCMode);
    const [ws] = useV1Socket();
    const { notify } = useNotifications();
    useEffect(() => {
        // If mouse leaves CC conversation view, the participants are hidden
        if (electronCaptionMode && !showBubbleAboveCCMode && participantPopoverOpen) {
            setParticipantPopoverOpen(false);
        }
    }, [electronCaptionMode, showBubbleAboveCCMode]);
    function dispatchSnack(message) {
        notify(message);
    }
    function update() {
        sendUpdateTranscriptOwnerMessage(ws, {
            avaId: participant.avaId,
            userName,
            title,
        });
        setEdit(false);
    }
    const handleNameChange = (ev) => {
        setUserName(ev.target.value);
    };
    const handleTitleChange = (ev) => {
        setTitle(ev.target.value);
    };
    const avatarSrc = (participant) => {
        if (isScribe) {
            return ScribeImg;
        }
        return participant.userAvatar || participant.photoUrl;
    };
    const cancelScribe = () => {
        /* eslint-disable no-shadow */
        const { scribeStatus } = props;
        if (ws) {
            const message = scribeStatus === 'ongoing' ? t('scribe.3dRemove') : t('scribe.3dCancel');
            sendTypeOnly(ws, 'end-scribe');
            dispatch(resetSelectedCaptions());
            dispatchSnack(message);
        }
        else {
            dispatchSnack(t('scribe.3dCancelFailed'));
        }
    };
    const handleClick = () => {
        if (canEdit || (canMute && !muted)) {
            setParticipantPopoverOpen((prev) => !prev);
        }
        else if (isScribe && !currentUserIsScribe) {
            setCancelScribePopoverOpen(!cancelScribePopoverOpen);
        }
    };
    const handleMute = () => {
        sendForceMute(ws, participant.avaId);
    };
    const handleBeginChat = () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield beginChatWithUser(participant.avaId, t('scribe.beginChatMessage'), firebaseUser.uid);
        const conversationId = result.data.conversation_id;
        window.open(`https://app.intercom.com/a/apps/eko73i1t/inbox/conversation/${conversationId}`, '_blank', {
            popup: true,
        });
    });
    // TODO: This could be a useMemo instead of a function.
    const getTooltipText = () => {
        const isElectron = window.isElectron && electronCaptionMode;
        const getMainText = () => {
            if (isScribe && !currentUserIsHost) {
                return t('scribe.scribe');
            }
            if (isScribe && currentUserIsHost) {
                if (scribeStatus === 'pending') {
                    return t('scribe.tooltipCancel');
                }
                if (scribeStatus === 'ongoing') {
                    return t('scribe.tooltipRemove');
                }
            }
            if (isElectron) {
                return participant.userName;
            }
            return participant.avaName
                ? `${participant.userName} (&${participant.avaName})`
                : `${participant.userName} (guest)`;
        };
        return `${getMainText()}${!isElectron && typeof index === 'number' ? ` - ${index}` : ''}`;
    };
    const tooltip = getTooltipText();
    const borderColor = (participant.theme && ((_b = (_a = participant.theme) === null || _a === void 0 ? void 0 : _a[`${theme.palette.mode}_theme`]) === null || _b === void 0 ? void 0 : _b.text_color)) || '#EEEEEE';
    const tooltipHasContent = !cancelScribePopoverOpen && !participantPopoverOpen;
    const shouldShowTooltip = window.isElectron && electronCaptionMode ? showBubbleAboveCCMode && tooltipHasContent : tooltipHasContent;
    return (React.createElement("div", null,
        edit && (React.createElement("form", { onSubmit: () => {
                // TODO: This onSubmit should be moved inside the Dialog below
                // to capture the key events correctly.
                update();
            } },
            React.createElement(Dialog, { open: true, TransitionComponent: Transition, onClose: () => setEdit(false) },
                React.createElement(DialogContent, { style: {
                        display: 'flex',
                        flexDirection: 'column',
                    } },
                    React.createElement(Typography, { variant: "h6" }, "Edit participant info"),
                    React.createElement(TextField, { variant: "standard", defaultValue: participant.userName, label: "userName", onChange: handleNameChange, autoFocus: true, id: "userName", style: { maxWidth: 300 }, margin: "normal", onKeyDown: (ev) => {
                            // TODO: if onSubmit is handled here, this special handler
                            // will not be needed.
                            if (['Enter'].includes(ev.key)) {
                                update();
                            }
                        } }),
                    React.createElement(TextField, { variant: "standard", defaultValue: participant.title, label: "title", onChange: handleTitleChange, id: "title", style: { minWidth: 300 }, margin: "normal", onKeyDown: (ev) => {
                            if (['Enter'].includes(ev.key)) {
                                update();
                            }
                        } }),
                    React.createElement("a", { href: `https://ava-parse-dashboard.herokuapp.com/apps/Ava%20Parse%20Prod/browser/Account?filters=%5B%7B%22field%22%3A%22avaId%22%2C%22constraint%22%3A%22eq%22%2C%22compareTo%22%3A%22${participant.id}%22%7D%5D`, 
                        // eslint-disable-next-line
                        target: "_blank", rel: "noreferrer" },
                        ' ',
                        "Update picture on parse",
                        ' ')),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { variant: "text", onClick: () => {
                            setEdit(false);
                            setUserName((participant || {}).userName);
                            setTitle((participant || {}).title);
                        } }, "Cancel"),
                    React.createElement(Button
                    // disabled={submitting || !this.isValid()}
                    , { 
                        // disabled={submitting || !this.isValid()}
                        variant: "contained", type: "submit", onClick: () => update(), color: "primary" }, "Save"))))),
        React.createElement(Tooltip, { disableInteractive: true, title: shouldShowTooltip ? tooltip : '', "aria-label": "share-link", enterDelay: 300, leaveDelay: 200 },
            React.createElement(Badge, { ref: avatarRef, badgeContent: numberOfConnections, color: "secondary", 
                // at this time it is not clear what this badge is supposed to indicate
                // in production, the scribe has a red badge and users have a green badge, which doesn't seem to be useful at all
                // I think we should hide this for now until we get a clear spec
                invisible: true, onClick: handleClick, variant: !numberOfConnections || numberOfConnections === 1 ? 'dot' : 'standard' },
                participant && avatarSrc(participant) ? (React.createElement(Avatar, { "aria-label": "participant-avatar", "data-qa": "participantAvatar", src: avatarSrc(participant), style: {
                        borderColor,
                        backgroundColor: borderColor,
                        borderRightColor: borderColor,
                        borderBottomColor: borderColor,
                        cursor: canEdit || canMute || scribeStatus ? 'pointer' : 'inherit',
                        WebkitMaskImage: '-webkit-radial-gradient(white, black)',
                    }, className: `${classes.avatar} ${size === 'big' ? classes.big : ''} ${listened ? classes.listened : ''}
                ${scribeStatus === 'pending' ? 'flash' : ''}
                ${scribeStatus ? 'pointer' : ''}` }, ((participant.userName || '')[0] || 'g').toUpperCase())) : (React.createElement("div", { className: classnames(classes.avatar, {
                        [classes.listened]: listened,
                        flash: scribeStatus === 'pending',
                        pointer: scribeStatus,
                    }), style: {
                        borderStyle: 'none',
                        cursor: canEdit || canMute || scribeStatus ? 'pointer' : 'inherit',
                        WebkitMaskImage: '-webkit-radial-gradient(white, black)',
                        height: size === 'big' ? 92 : 44,
                        width: size === 'big' ? 92 : 44,
                    } },
                    React.createElement(AvatarLogo
                    // eslint-disable-next-line no-nested-ternary
                    , { 
                        // eslint-disable-next-line no-nested-ternary
                        color: avaId === participant.avaId
                            ? theme.palette.mode === 'light'
                                ? theme.palette.ava.navyGroup.convoText
                                : theme.palette.ava.navyGroup.convoTextDark
                            : borderColor, width: size === 'big' ? 92 : 44, height: size === 'big' ? 92 : 44 }))),
                audio && (React.createElement("div", { className: classnames(classes.micContainer, { [classes.micAvatar]: participant && avatarSrc(participant) }, { [classes.micAvatarLogo]: !participant || !avatarSrc(participant) }, { muted: muted }) },
                    React.createElement("img", { src: AvaMic, alt: "ava_mic", height: "12" }))),
                avaId !== participant.avaId && (React.createElement(Popover, { open: electronCaptionMode ? showBubbleAboveCCMode && participantPopoverOpen : participantPopoverOpen, anchorEl: avatarRef.current, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'center' }, PaperProps: participantPaperProps },
                    React.createElement("div", { className: classes.popoverMenuContainer },
                        (isScribe || currentUserIsScribe) && (React.createElement(Button, { style: { fontWeight: 600 }, onClick: handleBeginChat }, t('scribe.beginChat'))),
                        canEdit && (React.createElement(Button, { className: classnames(classes.cancelScribeButton, {
                                [classes.cancelScribeButtonCCMode]: window.isElectron && electronCaptionMode,
                            }), onClick: () => setEdit(true) }, t('avatar.edit'))),
                        canMute && !muted && (React.createElement(Button, { className: classnames(classes.cancelScribeButton, {
                                [classes.cancelScribeButtonCCMode]: window.isElectron && electronCaptionMode,
                            }), onClick: handleMute }, t('avatar.mute')))))),
                isScribe && (React.createElement(Popover, { open: electronCaptionMode ? showBubbleAboveCCMode && cancelScribePopoverOpen : cancelScribePopoverOpen, anchorEl: avatarRef.current, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'center' }, PaperProps: participantPaperProps },
                    React.createElement("div", { className: classes.popoverMenuContainer },
                        React.createElement(Button, { style: { fontWeight: 600 }, onClick: handleBeginChat }, t('scribe.beginChat')),
                        currentUserIsHost && (React.createElement(Button, { className: classnames(classes.cancelScribeButton, {
                                [classes.cancelScribeButtonCCMode]: window.isElectron && electronCaptionMode,
                            }), onClick: cancelScribe }, scribeStatus === 'ongoing' ? t('scribe.remove') : t('scribe.cancel'))))))))));
}, (prevProps, nextProps) => {
    const differingProps = differingKeys(prevProps, nextProps);
    if (differingProps.length === 0)
        return true;
    if (differingProps.length > 1) {
        return false;
    }
    if (differingProps.length === 1) {
        if (differingProps[0] !== 'participant') {
            return false;
        }
        // The only thing that changed is `participant`. This is fairly common, as the
        // participant object is recreated on every room-status-update. That's why we
        // only update if something important changed.
        const differingUserKeys = differingKeys(prevProps.participant, nextProps.participant);
        let shallowDifferences = differingUserKeys.length;
        // `flags` and `theme` are objects, but are fairly shallow, so we can compare
        // them deeply. If any of them changed, or any other property is different
        // then we render the component.
        if (differingUserKeys.includes('flags')) {
            if (!isEqual(prevProps.participant.flags, nextProps.participant.flags)) {
                return false;
            }
            shallowDifferences--;
        }
        if (differingUserKeys.includes('theme')) {
            if (!isEqual(prevProps.participant.theme, nextProps.participant.theme)) {
                return false;
            }
            shallowDifferences--;
        }
        return shallowDifferences === 0;
    }
    return false;
});
export default ParticipantAvatar;
