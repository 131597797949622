export function sortedSpeakers(speakers, userAvaId, hostAvaId) {
    const speakerSortScore = (speaker) => {
        if (speaker.avaId === userAvaId) {
            return 2;
        }
        if (speaker.avaId === hostAvaId) {
            return 1;
        }
        return 0;
    };
    return [...speakers].sort((left, right) => {
        const leftScore = speakerSortScore(left);
        const rightScore = speakerSortScore(right);
        if (leftScore < rightScore) {
            return 1;
        }
        if (leftScore > rightScore) {
            return -1;
        }
        return left.userName.localeCompare(right.userName);
    });
}
export function getSpeakerLabels(t, speaker, userAvaId, hostAvaId) {
    const labels = [];
    if (speaker.avaId === hostAvaId) {
        labels.push(t('convoV2.participants.host'));
    }
    if (speaker.avaId === userAvaId) {
        labels.push(t('convoV2.participants.me'));
    }
    return labels;
}
