import { createSelector } from 'reselect';
import { last } from '../utils/lodash';
const getSpeechBlocs = (state) => state.speechBlocs.blocs;
export const blocsGroupedBySpeaker = createSelector([getSpeechBlocs], (speechBlocs) => {
    const groups = [];
    speechBlocs.forEach((bloc) => {
        var _a;
        if (groups.length > 0 && (last(groups) || [])[0].speakerId === bloc.speakerId) {
            return (_a = last(groups)) === null || _a === void 0 ? void 0 : _a.push(bloc);
        }
        return groups.push([bloc]);
    });
    return groups;
});
const getTranscripts = (state) => state.scribeConversation.transcriptsCurrent.map((tid) => state.scribeConversation.transcripts[tid]);
const getFinalTranscripts = (state) => state.scribeConversation.transcriptsFinal;
export const getRecording = (state) => state.scribeConversation.recording;
