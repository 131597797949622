import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import DropdownMenu from '../Dropdowns/DropdownMenu';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
const getBorderColor = (open, theme) => {
    return open ? theme.palette.ava.blue2 : theme.palette.ava.grey6;
};
const getBackgroundStyle = (transparentBg, theme) => {
    if (transparentBg)
        return 'transparent';
    return theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark1;
};
const useStyles = makeStyles((theme) => ({
    inputRoot: {
        width: 282,
        background: (props) => getBackgroundStyle(props.transparentBg, theme),
        borderRadius: 8,
        caretColor: 'transparent',
        cursor: 'pointer !important',
        borderWidth: 1,
        '& $notchedOutline, &$disabled $notchedOutline, &$focused $notchedOutline': {
            borderColor: (props) => getBorderColor(props.open, theme),
            borderWidth: 1,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: (props) => getBorderColor(props.open, theme),
            '@media (hover: none)': {
                borderColor: (props) => getBorderColor(props.open, theme),
            },
        },
        [theme.breakpoints.down('sm')]: {
            width: 200,
        },
    },
    input: {
        paddingTop: 11,
        paddingRight: 16,
        paddingBottom: 11,
        paddingLeft: 16,
        fontSize: 18,
        color: (props) => {
            if (props.open)
                return theme.palette.ava.blue2;
            return theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white;
        },
        cursor: 'pointer !important',
    },
    focused: {
        borderColor: (props) => getBorderColor(props.open, theme),
    },
    disabled: {
        borderColor: (props) => getBorderColor(props.open, theme),
    },
    notchedOutline: {
        borderColor: (props) => getBorderColor(props.open, theme),
    },
    arrow: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
    },
    blue: {
        color: theme.palette.ava.blue2,
    },
    error: {},
}));
const SelectionInput = ({ buttonId, children, dropdownId, loading, placeholder, transparentBg, value }) => {
    var _a;
    const ref = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [menuWidth, setMenuWidth] = useState(0);
    const classes = useStyles({ open: Boolean(anchorEl), transparentBg });
    useEffect(() => {
        var _a;
        if ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) {
            setMenuWidth(ref.current.offsetWidth);
        }
    }, [(_a = ref.current) === null || _a === void 0 ? void 0 : _a.offsetWidth]);
    const onClick = (event) => setAnchorEl(event.currentTarget);
    const onClose = () => setAnchorEl(null);
    return (React.createElement(React.Fragment, null,
        React.createElement(OutlinedInput, { ref: ref, id: buttonId, classes: {
                root: classes.inputRoot,
                input: classes.input,
                disabled: classes.disabled,
                focused: classes.focused,
                notchedOutline: classes.notchedOutline,
                error: classes.error,
            }, "aria-controls": dropdownId, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, placeholder: placeholder, value: value, startAdornment: loading && (React.createElement(CircularProgress, { className: classnames(classes.blue, classes.loading), style: { width: 20, height: 20 } })), endAdornment: React.createElement(ArrowDownIcon, { className: classnames(classes.arrow, { [classes.blue]: open }), rotated: open }), onClick: onClick }),
        React.createElement(DropdownMenu, { id: dropdownId, paperStyle: { marginTop: 8 }, anchorEl: anchorEl, width: menuWidth, onClose: onClose }, children)));
};
export default SelectionInput;
