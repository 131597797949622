import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    placeholder: {
        color: ({ header }) => (header ? theme.palette.ava.navy : theme.palette.ava.blue2),
        fontSize: ({ landing, fr, header }) => (landing ? 20 : fr && header ? 16 : 18),
        left: 2,
        marginLeft: ({ header, autoFocus, landing, joinAsScribe }) => (autoFocus && !landing) || joinAsScribe || header ? 16 : 0,
        overflow: 'hidden',
        position: 'absolute',
        width: ({ autoFocus }) => autoFocus ? `calc(100% - ${autoFocus ? 16 : 30}px)` : 'calc(100% - 15px)',
        textAlign: ({ autoFocus, landing }) => autoFocus === false || landing ? 'center' : 'left',
    },
}));
const JoinConversationPlaceholder = ({ innerProps, selectProps }) => {
    const classes = useStyles({
        autoFocus: selectProps.autoFocus,
        joinAsScribe: selectProps.joinAsScribe,
        header: selectProps.header,
        landing: selectProps.landing,
        fr: selectProps.fr,
    });
    return (React.createElement(Typography, Object.assign({ color: "textSecondary", className: classes.placeholder }, innerProps, { noWrap: true }), selectProps.placeholder));
};
export default JoinConversationPlaceholder;
