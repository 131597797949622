import React, { useRef } from 'react';
import { generateRandomId } from '../../utils';
const AvaMagnifying = ({ width, height }) => {
    const randomId = useRef(generateRandomId());
    return (React.createElement("svg", { width: width || 24, height: height || 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("mask", { id: "mask0_3234_35555", style: { maskType: 'alpha' }, maskUnits: "userSpaceOnUse", x: "3", y: "1", width: "18", height: "18" },
            React.createElement("rect", { x: "3", y: "1", width: "18", height: "18", fill: "white" })),
        React.createElement("g", { mask: `url(#mask0_${randomId.current})` },
            React.createElement("path", { d: "M11.9998 18.6325C16.7675 18.6325 20.6325 14.7675 20.6325 9.99984C20.6325 5.23216 16.7675 1.36719 11.9998 1.36719C7.23216 1.36719 3.36719 5.23216 3.36719 9.99984C3.36719 14.7675 7.23216 18.6325 11.9998 18.6325Z", fill: `url(#paint0_linear_${randomId.current}` }),
            React.createElement("g", { filter: `url(#filter0_${randomId.current})` },
                React.createElement("path", { d: "M11.9997 17.1634C15.9559 17.1634 19.163 13.9563 19.163 10.0002C19.163 6.04402 15.9559 2.83691 11.9997 2.83691C8.04353 2.83691 4.83643 6.04402 4.83643 10.0002C4.83643 13.9563 8.04353 17.1634 11.9997 17.1634Z", fill: "#50ACED" })),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.1438 13.3628C15.9116 13.2102 15.6875 12.9999 15.4471 12.7581L15.1857 12.4898C15.3832 12.1758 15.7568 11.5001 16.2931 11.1506C16.5441 10.9868 16.6635 10.6879 16.5545 10.412L16.5446 10.3872C16.3988 10.019 15.9383 9.88582 15.611 10.1153C14.9636 10.5688 14.5026 11.2235 14.2518 11.5696L14.2498 11.5677V11.5655L14.2491 11.567L14.2451 11.5628V11.5672L12.1627 9.5091C12.7468 9.11488 14.1845 8.18086 14.1892 6.77266C14.1934 5.49644 13.1659 4.43677 11.5222 4.43166C9.68446 4.42559 8.78352 5.68431 8.7798 6.79916C8.76173 7.67922 9.22297 8.32596 9.80354 8.92944L10.1462 9.29717L10.1459 9.32657C9.60696 9.63281 8.06504 10.4934 8.0591 12.2538C8.05415 13.7206 9.18558 14.9859 11.0682 14.9919C12.7121 14.9973 13.7903 14.2526 14.3305 13.653L14.9517 12.8079L14.4915 13.8003L14.7301 14.0443C14.9423 14.2475 15.1698 14.4171 15.4142 14.5518C15.7739 14.7499 16.2312 14.579 16.3931 14.2069C16.5256 13.9026 16.4238 13.547 16.1438 13.3628ZM10.1096 6.81811C10.112 6.1286 10.6069 5.55817 11.4438 5.56085C12.3402 5.56376 12.8611 6.19616 12.8592 6.82686C12.8557 7.80973 11.748 8.37822 11.2989 8.64095L10.9714 8.31721C10.4648 7.81678 10.0927 7.39 10.1096 6.81811ZM11.192 13.7602C10.2358 13.757 9.47575 13.0945 9.47897 12.1702C9.48243 11.1458 10.2665 10.5484 10.7691 10.2319L10.3141 9.46438L11.0461 10.219L11.0464 10.2215L13.437 12.7112C13.002 13.2085 12.2679 13.7636 11.192 13.7602Z", fill: "white" })),
        React.createElement("circle", { opacity: "0.6", cx: "12.5", cy: "12.5", r: "7.5", fill: `url(#paint1_linear_${randomId.current}` }),
        React.createElement("g", { clipPath: `url(#clip0_${randomId.current})` },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4 12.8076C4 7.95121 7.95121 4 12.8076 4C17.6642 4 21.6152 7.95121 21.6152 12.8076C21.6152 14.9438 20.8507 16.9048 19.5811 18.4315L23.7624 22.6128C24.0801 22.9302 24.0801 23.4451 23.7624 23.7625C23.6036 23.9212 23.3955 24.0007 23.1876 24.0007C22.9795 24.0007 22.7716 23.9212 22.6128 23.7625L18.4315 19.5811C16.9048 20.8507 14.9438 21.6152 12.8076 21.6152C7.95121 21.6152 4 17.6642 4 12.8076ZM5.62602 12.8076C5.62602 16.7675 8.8477 19.9892 12.8076 19.9892C16.7675 19.9892 19.9891 16.7675 19.9891 12.8076C19.9891 8.8477 16.7675 5.62602 12.8076 5.62602C8.8477 5.62602 5.62602 8.84773 5.62602 12.8076Z", fill: "black" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: `filter0_${randomId.current}`, x: "3.43018", y: "1.43066", width: "17.1392", height: "17.1392", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }),
                React.createElement("feGaussianBlur", { stdDeviation: "0.703125", result: "effect1_foregroundBlur_3234_35555" })),
            React.createElement("linearGradient", { id: `paint0_linear_${randomId.current}`, x1: "11.5364", y1: "21.4157", x2: "23.0384", y2: "10.5819", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#5BC6FF" }),
                React.createElement("stop", { offset: "1", stopColor: "#4DA7DB" })),
            React.createElement("linearGradient", { id: `paint1_linear_${randomId.current}`, x1: "6.59547", y1: "8.19144", x2: "17.6061", y2: "18.085", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#F7FBFF" }),
                React.createElement("stop", { offset: "1", stopColor: "#D5D7D9" })),
            React.createElement("clipPath", { id: `clip0_${randomId.current}` },
                React.createElement("rect", { width: "20", height: "20", fill: "white", transform: "translate(4 4)" })))));
};
export default AvaMagnifying;
