import { useTheme } from '@mui/material/styles';
import React from 'react';
const Transcript = ({ width, height }) => {
    const theme = useTheme();
    if (theme.palette.mode === 'light') {
        return (React.createElement("svg", { width: width || '48', height: height || '48', viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("circle", { cx: "24", cy: "24", r: "24", fill: "#D0E9FB", fillOpacity: "0.7" }),
            React.createElement("rect", { x: "13", y: "13", width: "22", height: "22", rx: "2.69231", stroke: "#4283B2", strokeWidth: "2" }),
            React.createElement("line", { x1: "18", y1: "24", x2: "30", y2: "24", stroke: "#4283B2", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("line", { x1: "18", y1: "29", x2: "30", y2: "29", stroke: "#4283B2", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("line", { x1: "18", y1: "19", x2: "30", y2: "19", stroke: "#4283B2", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
    }
    return (React.createElement("svg", { width: width || '48', height: height || '48', viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("circle", { cx: "24", cy: "24", r: "24", fill: "#5B575E" }),
        React.createElement("rect", { x: "13", y: "13", width: "22", height: "22", rx: "2.69231", stroke: "#B0D9F8", strokeWidth: "2" }),
        React.createElement("line", { x1: "18", y1: "24", x2: "30", y2: "24", stroke: "#B0D9F8", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("line", { x1: "18", y1: "29", x2: "30", y2: "29", stroke: "#B0D9F8", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("line", { x1: "18", y1: "19", x2: "30", y2: "19", stroke: "#B0D9F8", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })));
};
export default Transcript;
