class AccountProviders {
    constructor() {
        this.apple = false;
        this.facebook = false;
        this.google = false;
        this.email = false;
        this.phone = false;
        this.password = false;
        this.sso = false;
    }
    getProviders() {
        return {
            apple: this.apple,
            facebook: this.facebook,
            google: this.google,
            email: this.email,
            phone: this.phone,
            password: this.password,
            sso: this.sso,
        };
    }
    updateProviders(providers) {
        providers.forEach((provider) => {
            if (provider === 'apple.com')
                this.apple = true;
            else if (provider === 'facebook.com')
                this.facebook = true;
            else if (provider === 'google.com')
                this.google = true;
            else if (provider === 'password')
                this.email = true;
            else if (provider === 'phone')
                this.phone = true;
            else if (provider.includes('saml') || provider.includes('oidc'))
                this.sso = true;
        });
    }
}
export default AccountProviders;
