import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Button, ClickAwayListener, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectCurrentSelectedVoice } from '../../selectors/textToSpeech';
import { setDisplayedItemsCount } from '../../store/slices/textToSpeech';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { TtsSelectedVoice } from './TtsSelectedVoice';
import { TtsSelector } from './TtsSelector';
const getBorderColor = (open, theme) => {
    return open ? theme.palette.ava.blue2 : theme.palette.ava.grey6;
};
const useStyles = makeStyles((theme) => ({
    inputRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 282,
        height: 48,
        background: 'transparent',
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: (props) => getBorderColor(props.open, theme),
        caretColor: 'transparent',
        cursor: 'pointer !important',
        paddingTop: 11,
        paddingRight: 16,
        paddingBottom: 11,
        paddingLeft: 16,
        '&:hover': {
            background: 'transparent',
            boxShadow: 'none',
        },
        boxShadow: 'none',
        fontSize: (props) => (props.currentSelectedVoice ? 16 : 18),
        color: (props) => {
            if (props.open)
                return theme.palette.ava.blue2;
            if (props.inactive)
                return theme.palette.ava.text1;
            return theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white;
        },
        [theme.breakpoints.down('sm')]: {
            width: 200,
        },
    },
    selectVoice: {
        color: theme.palette.ava.text1,
    },
    title: {
        fontWeight: 700,
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        fontSize: 20,
    },
}));
export const TtsPopoverSelection = ({ showVoices }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles({ open: anchorEl });
    const currentSelectedVoice = useAppSelector(selectCurrentSelectedVoice);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const paperStyles = showVoices
        ? {}
        : {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        dispatch(setDisplayedItemsCount(2));
    };
    const open = Boolean(anchorEl);
    return (React.createElement(ClickAwayListener, { onClickAway: handleClose },
        React.createElement("div", null,
            React.createElement(Button, { className: classes.inputRoot, "aria-describedby": "customized-menu", variant: "contained", onClick: handleClick },
                currentSelectedVoice ? (React.createElement(TtsSelectedVoice, { currentSelectedVoice: currentSelectedVoice })) : (React.createElement(Typography, { className: classes.selectVoice }, t('options.selectVoice'))),
                React.createElement("div", { style: { display: 'flex' } }, anchorEl ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null))),
            React.createElement(Popover, { id: "customized-menu", open: open, onSelect: handleClose, anchorEl: anchorEl, onClose: handleClose, PaperProps: {
                    style: Object.assign(Object.assign({}, paperStyles), { marginTop: 8, padding: showVoices ? '10px 10px' : '20px 12px', flexDirection: showVoices ? undefined : 'column', width: showVoices ? 340 : 302, maxHeight: 220, borderRadius: 20, gap: 16 }),
                }, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                } },
                React.createElement(TtsSelector, { closeMenu: () => handleClose(), showVoices: showVoices })))));
};
