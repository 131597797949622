import { InputLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    label: {
        width: '100%',
        fontSize: (props) => (props.size === 'small' ? 12 : 16),
        color: theme.palette.ava.text1,
    },
    input: {
        height: (props) => (props.size === 'small' ? 36 : 48),
        padding: '0px 8px',
        borderRadius: 8,
        border: `1px solid ${theme.palette.ava.grey7}`,
        width: '100%',
        fontSize: (props) => (props.size === 'small' ? 12 : 18),
        marginBottom: (props) => (props.error ? 0 : props.size === 'small' ? 2 : 16),
        boxShadow: '0px 2px 8px 0px rgba(74, 74, 74, 0.09)',
        '&::placeholder': {
            color: theme.palette.ava.text1,
        },
        '& .MuiInputAdornment-root': {
            height: 'auto',
            maxHeight: 'none',
            marginLeft: 0,
            padding: 6,
        },
    },
}));
function LoginTextField({ onChange, label, name, type, placeholder, value, helperText, endAdornment, autoFocus, error, onKeyDown, size, }) {
    const classes = useStyles({ error, size });
    return (React.createElement(React.Fragment, null,
        React.createElement(InputLabel, { className: classes.label }, label),
        React.createElement(TextField, { variant: "outlined", fullWidth: true, autoFocus: autoFocus, name: name, type: type, id: name, onKeyDown: (event) => {
                if (onKeyDown)
                    onKeyDown(event);
            }, "data-qa": name, placeholder: placeholder, autoComplete: type === 'password' ? 'current-password' : 'off', value: value, onChange: (event) => onChange(event.target.value), helperText: helperText, InputProps: {
                className: classes.input,
                endAdornment,
            }, error: error })));
}
export default LoginTextField;
