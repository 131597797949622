import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { getImageNameForVoice } from '../../utils/textToSpeech';
import TtsImage from './TtsImage';
const useStyles = makeStyles((theme) => ({
    selectedVoicePrimary: {
        fontWeight: 600,
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    selectedVoiceSecondary: {
        fontSize: 14,
        color: theme.palette.ava.text1,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },
    },
    image: {
        [theme.breakpoints.down('sm')]: {
            width: 25,
        },
    },
}));
export const TtsSelectedVoice = ({ currentSelectedVoice, isDropdown }) => {
    var _a;
    const classes = useStyles();
    const imageName = (_a = getImageNameForVoice(currentSelectedVoice)) !== null && _a !== void 0 ? _a : '';
    return (React.createElement(React.Fragment, null,
        !isDropdown && (React.createElement(TtsImage, { className: classes.image, imageName: imageName, imageAltText: currentSelectedVoice.name })),
        React.createElement("div", null,
            React.createElement(Typography, { className: classes.selectedVoicePrimary }, currentSelectedVoice.name),
            React.createElement(Typography, { className: classes.selectedVoiceSecondary },
                currentSelectedVoice.tones[0],
                " \u2022 ",
                currentSelectedVoice.tones[1]))));
};
